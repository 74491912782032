import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { CartProductsGetter, CartState } from "./state";

export type Getters = {
  getCartItems(state: CartState, rootState: RootState): CartProductsGetter[];
  getCartSize(state: CartState, rootState: RootState): number;
  getCartTotalAmount(state: CartState, rootState: RootState): number;
  getShippingMethods(state: CartState, rootState: RootState): any[];
  getSelectedShippingMethod(state: CartState, rootState: RootState): any;
  getActiveOrder(state: CartState, rootState: RootState): any;
};
export const getters: GetterTree<CartState, RootState> & Getters = {
  getCartItems: (state) => {
    return state.cartItems;
  },
  getCartSize: (state) => {
    return state.cartItems.length;
  },

  getCartTotalAmount:(state)=>{
      return state.cartItems.reduce((a,b)=> {
          return a + (b.variantPrice*b.quantity)},0)
  },

  getShippingMethods: (state) => {
    return state.shippingMethods;
  },

  getSelectedShippingMethod: (state) => {
    return state.selectedShippingMethod;
  },
  getActiveOrder: (state) => {
    return state.activeOrder;
  },
};
