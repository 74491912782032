<template>
  <h4>Added to Cart</h4>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
name:"ava-customizedcontent",
props:{
    item:{
        type: {},
        default:null
    }
}
});
</script>

<style>

</style>