import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import MainLayout from '../views/MainLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    children: [
      // {
      //   path: '',
      //   redirect: 'home'
      // },
      {
        path: '',
        name: 'Shop',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'product/:id',
        name: 'Product',
        component: () => import('@/views/Product.vue')
      },
      {
        path: 'cart',
        name: 'Cart',
        component: () => import('@/views/Cart.vue')
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: () => import('@/views/Checkout.vue')
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('@/views/Account.vue')
      },
      {
        path: 'verify',
        name: 'VerifyAccount',
        component: () => import('@/views/VerifyAccount.vue')
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword.vue')
      },
      {
        path: 'change-email-address',
        name: 'ChangeEmailAddress',
        component: () => import('@/views/ChangeEmailAddress.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'contact_us',
        name: 'Contact Us',
        component: () => import('@/views/Contact.vue')
      },
      {
        path: 'prescription',
        name: 'Upload Prescription',
        component: () => import('@/views/Prescription.vue'),
        meta: {
        title: 'Prescription Upload - Ava Pharmacy Ltd.',
          metaTags: [
            {
              name: 'description',
              content: 'The Prescription Upload page of our Ava Pharmacy Ltd app.'
            },
            {
              property: 'og:description',
              content: 'The Prescription Upload page of our Ava Pharmacy Ltd app.'
            }
          ]
        }
      },
      {
        path: 'category/:id',
        name: 'Category',
        component: () => import('@/views/Category.vue'),
        meta: {
          title: 'Category - Ava Pharmacy Ltd.',
          metaTags: [
            {
              name: 'category',
              content: 'View products per category.'
            },
            {
              property: 'og:category',
              content: 'View products per category.'
            }
          ]
        }
      },
      {
        path: 'brands/:brand',
        name: 'Brands',
        component: () => import('@/views/Brands.vue'),
        meta: {
          title: 'Brand - Ava Pharmacy Ltd.',
          metaTags: [
            {
              name: 'brands',
              content: 'View products per Brand.'
            },
            {
              property: 'og:brands',
              content: 'View products per Brand.'
            }
          ]
        }
      },
      {
        path: 'categories',
        name: 'Categories',
        component: () => import('@/views/Categories.vue'),
        meta: {
          title: 'Categories - Ava Pharmacy Ltd.',
          metaTags: [
            {
              name: 'category',
              content: 'View products in all Categories.'
            },
            {
              property: 'og:category',
              content: 'View products in all Categories.'
            }
          ]
        }
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/Search.vue'),
        meta: {
          title: 'Search Results - Ava Pharmacy Ltd.',
          metaTags: [
            {
              name: 'category',
              content: 'View Search Results'
            },
            {
              property: 'og:category',
              content: 'View Search Results'
            }
          ]
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
