
export enum CheckoutMutationTypes {
  STORE_PAYMENT_METHODS = 'STORE_PAYMENT_METHODS',
  SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD',
  SET_ELIGIBLE_SHIPPING_METHODS = 'GET_ELIGIBLE_SHIPPING_METHODS',
  SET_CUSTOMER_FOR_ORDER = 'SET_CUSTOMER_FOR_ORDER',
  SET_ORDER_BILLING_ADDRESS = 'SET_ORDER_BILLING_ADDRESS',
  SET_ORDER_SHIPPING_ADDRESS = 'SET_ORDER_SHIPPING_ADDRESS',
  SET_ORDER_SHIPPING_METHOD = 'SET_ORDER_SHIPPING_METHOD',
  ADD_PAYMENT_TO_ORDER = 'ADD_PAYMENT_TO_ORDER',
  TRANSITION_ORDER_TO_STATE = 'TRANSITION_ORDER_TO_STATE',
}
