export type CoreState={
    showNotification: boolean;
    fetchingActiveCutomer: boolean;
    fetchingCollections: boolean;
    fetchingProducts: boolean;
    userCreatingPrescription: boolean;
    userAUthenticating: boolean;
    priceWithTaxMin: number;
    priceWithTaxMax: number;
}
export const state: CoreState ={
    showNotification: true,
    fetchingActiveCutomer: false,
    fetchingCollections: false,
    fetchingProducts: false,
    userCreatingPrescription: false,
    userAUthenticating: false,
    priceWithTaxMin: 0,
    priceWithTaxMax: 0,
}