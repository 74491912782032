import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { CoreActionTypes } from '@/store/modules/core/action-types';
import { CoreMutationTypes } from './mutation-types';
import { Mutations } from "./mutations";
import { CoreState } from '@/store/modules/core';
import { apolloClient } from '@/main'
import { GET_BANNERS, GET_ADJUSTMENT_OPERATIONS, SEARCH_DOCUMENT, GET_ACTIVE_PROMOTIONS } from "@/grapql/queries";
import { isNullableType } from "graphql";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<CoreState, RootState>, "commit">;

export interface Actions {
  [CoreActionTypes.SHOW_NOTIFICATION]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
  [CoreActionTypes.FETCHING_ACTIVE_CUSTOMER]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
  [CoreActionTypes.FETCHING_COLLECTIONS]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
  [CoreActionTypes.FETCHING_PRODUCTS]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
  [CoreActionTypes.USER_CREATING_PRESCRIPTION]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
  [CoreActionTypes.USER_AUTHENTICATING]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
  [CoreActionTypes.SEARCH_PRODUCT]({ commit }: AugmentedActionContext, payload: any): Promise<[]>;
  [CoreActionTypes.PRICE_RANGE]({ commit }: AugmentedActionContext, payload: any): Promise<[]>;
  [CoreActionTypes.GET_BANNERS]({ commit }: AugmentedActionContext, bannerSection: string): Promise<[]>;
  [CoreActionTypes.GET_ACTIVE_PROMOTIONS]({ commit }: AugmentedActionContext): Promise<[]>;
  [CoreActionTypes.GET_ADJUSTMENT_OPERATIONS](): Promise<unknown>;
}

export const actions: ActionTree<CoreState, RootState> & Actions = {

  async [CoreActionTypes.GET_BANNERS]({ commit }, bannerSection: string) {
    const { data } = await apolloClient.query({query: GET_BANNERS, variables:{ productPromotionsOptions: {
      "filter": {
        "bannerSection": {
          "eq": bannerSection
        }
      }
    }}})  
     return new Promise((res,rej) => {  
      setTimeout(() => {
        if(data?.productPromotions["__typename"] =="ProductPromotionList") {
          return res(data.productPromotions.items);
        } else {
        return rej(data.productPromotions.message)
        }
      }, 500);
    });
  },  

  async [CoreActionTypes.GET_ACTIVE_PROMOTIONS]({ commit }) {
    const { data } = await apolloClient.query({query: GET_ACTIVE_PROMOTIONS, variables:{ activePromotionsOptions: {
      "filter": {
        "enabled": {
          "eq": true
        }
      }
    }}})  
     return new Promise((res,rej) => {  
      setTimeout(() => {
        if(data?.activePromotions["__typename"] =="ProductPromotionList") {
          return res(data.activePromotions.items);
        } else {
        return rej([])
        }
      }, 500);
    });
  },

  async [CoreActionTypes.GET_ADJUSTMENT_OPERATIONS]() {
    const { data } = await apolloClient.query({query: GET_ADJUSTMENT_OPERATIONS, variables:{}})  
     return new Promise((res,rej) => {  
      setTimeout(() => {
        if(data?.promotionActionsFr.length>0) {
          return res({promotionActionsFr:data.promotionActionsFr,promotionConditionsFr:data.promotionConditionsFr});
        } else {
        return rej(data.promotionActionsFr)
        }
      }, 500);
    });
  }, 

  async [CoreActionTypes.SHOW_NOTIFICATION]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CoreMutationTypes.SET_NOTIFICATION, payload);
        return true;
      }, 500);
    });
  },

  async [CoreActionTypes.FETCHING_ACTIVE_CUSTOMER]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CoreMutationTypes.SET_FETCHING_ACTIVE_CUSTOMER, payload);
        return true;
      }, 500);
    });
  },

  async [CoreActionTypes.FETCHING_COLLECTIONS]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CoreMutationTypes.SET_FETCHING_COLLECTIONS, payload);
        return true;
      }, 500);
    });
  },
  
  async [CoreActionTypes.FETCHING_PRODUCTS]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CoreMutationTypes.SET_FETCHING_PRODUCTS, payload);
        return true;
      }, 500);
    });
  },
  async [CoreActionTypes.USER_CREATING_PRESCRIPTION]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CoreMutationTypes.SET_USER_CREATING_PRESCRIPTION, payload);
        return true;
      }, 500);
    });
  },
  async [CoreActionTypes.USER_AUTHENTICATING]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CoreMutationTypes.SET_USER_AUTHENTICATING, payload);
        return true;
      }, 500);
    });
  },

  async [CoreActionTypes.SEARCH_PRODUCT]({ commit}, payload: any ) {
    const { data } = await apolloClient.query({query: SEARCH_DOCUMENT,variables:{skip: 0, take: 12, searchInput:payload.input, groupByProduct: true,}})    
      return new Promise((res,rej) => {  
        setTimeout(() => {
          if(data?.search["__typename"] =="SearchResponse") {
            return res(data.search);
          } else {
          return rej(data.search)
          }
        }, 500);
      });
  },
  async [CoreActionTypes.PRICE_RANGE]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CoreMutationTypes.SET_PRICE_RANGE, payload);
        return true;
      }, 500);
    });
  },
}


