import { MutationTree } from "vuex";
import { State } from "./state";
import { UserMutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
  [UserMutationTypes.LOGIN_USER](state: S, payload: any): void;
  [UserMutationTypes.SET_UPDATE_ACTIVE_CUSTOMER](state: S, payload: any): void;
  [UserMutationTypes.SET_ACTIVE_CUSTOMER](state: S, payload: any): void;
  [UserMutationTypes.LOGOUT_USER](state: S): void;
  [UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER](state: S): void;

};

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.LOGIN_USER](state: State, me) {
    state.status = true

  },
  [UserMutationTypes.SET_UPDATE_ACTIVE_CUSTOMER](state: State, me) {
    state.status = true

  },
  [UserMutationTypes.SET_ACTIVE_CUSTOMER](state: State, me) {
    state.status = true
    state.activeCustomer = me
  },
  [UserMutationTypes.LOGOUT_USER](state: State) {
    state.status = !true
    state.activeCustomer = undefined

  },
  [UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER](state: State) {
    state.status = !state.status
  },
};
