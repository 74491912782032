import { MutationTree } from 'vuex';

import { CheckoutState } from './state';
import { CheckoutMutationTypes } from './mutation-types';

export type Mutations<S = CheckoutState> = {
  [CheckoutMutationTypes.STORE_PAYMENT_METHODS](state: S, payload: any): void;
  [CheckoutMutationTypes.SET_ELIGIBLE_SHIPPING_METHODS](state: S, payload: any): void;
  [CheckoutMutationTypes.SET_SELECTED_PAYMENT_METHOD](state: S, payload: any): void;
}

export const mutations: MutationTree<CheckoutState> & Mutations = {
  [CheckoutMutationTypes.STORE_PAYMENT_METHODS](state: CheckoutState, paymentMethods: any[]) {
    state.paymentMethods = [...paymentMethods];
  },
  [CheckoutMutationTypes.SET_ELIGIBLE_SHIPPING_METHODS](state: CheckoutState, shippingMethods: any[]) {
    state.shippingMethods = [...shippingMethods];
  },
  [CheckoutMutationTypes.SET_SELECTED_PAYMENT_METHOD](state: CheckoutState, selectedPaymentMethod: any) {
    state.selectedpaymentMethod = selectedPaymentMethod;
  },
};
