
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { CollectionsActionTypes } from '@/store/modules/collections/action-types';
import { ToastNotify } from '@/lib/toast-notify';
import { ProductsActionTypes } from '@/store/modules/products/action-types';
import { AuthActionTypes } from '@/store/modules/auth/action-types';
import { CartActionTypes } from '@/store/modules/cart/action-types';
// const isStatusBarLight = true;
 const store = useStore();

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
    created() {
        async function fetchCollections() {
            try {
                if (store.getters.getCollections.length == 0) {
                    await store.dispatch(CollectionsActionTypes.FETCH_COLLECTIONS, undefined);
                }
            } catch (error) {
                new ToastNotify({title: 'Data Error',description: error}, 'danger')
            }
        }
        async function fetchProducts() {
            try {
                if (store.getters.getProducts.length == 0) {
                    await store.dispatch(ProductsActionTypes.FETCH_PRODUCTS, undefined);
                }
            } catch (error) {
                new ToastNotify({title: 'Data Error',description: error}, 'danger')
            }
        }
        
        async function fetchTopSellerProducts() {
            try {
                if (store.getters.getBestSellers.length == 0) {
                    await store.dispatch(ProductsActionTypes.FETCH_TOPSELLERS, undefined);
                }
                 if (store.getters.getNewArrivals.length == 0) {
                    await store.dispatch(ProductsActionTypes.FETCH_LATEST_PRODUCTS, undefined);
                }
                 if (store.getters.getTopRated.length == 0) {
                    await store.dispatch(ProductsActionTypes.FETCH_TOPRATED_PRODUCTS, undefined);
                }
            } catch (error) {
                new ToastNotify({title: 'Data Error',description: error}, 'danger')
            }
        }

        function getActiveCustomer() {            
            if (!store.getters.getActiveCustomer) {
                try {
                    store.dispatch(AuthActionTypes.GET_ACTIVE_CUSTOMER, undefined);
                } catch (error) {
                     new ToastNotify({title: 'Data Error',description: error}, 'danger')
                }
            }
        }
        function fecthActiveCustomer() {            
            
                try {
                    store.dispatch(AuthActionTypes.GET_ACTIVE_CUSTOMER, undefined);
                } catch (error) {
                     new ToastNotify({title: 'Data Error',description: error}, 'danger')
                }
        }
        function getActiveOrder() {
                try {
                    store.dispatch(CartActionTypes.GET_ACTIVE_ORDER, undefined);
                } catch (error) {
                     new ToastNotify({title: 'Data Error',description: error}, 'danger')
                }
        }
            store.subscribe((mutation) => {
            if (mutation.type == "RE_FECTH_ACTIVE_CUSTOMER") {
                fecthActiveCustomer();
            }
            });
        getActiveCustomer();
        getActiveOrder();
        fetchCollections();
        fetchProducts();
        fetchTopSellerProducts();

        return {
        };
    },
    watch: {
      '$route' (to, from) {          
        document.title = to.meta.title || 'Ava Pharmacy Ltd.'
      }
    },
    computed: {
    currentRouteName() {
        return this.$route.name;
    }
    },
});
