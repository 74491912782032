import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { WishListProductsGetter, WishState } from "./state";

export type Getters = {
  getWishListItems(state: WishState, rootState: RootState): WishListProductsGetter[];
  getWishListSize(state: WishState, rootState: RootState): number;
  getWishListTotalAmount(state: WishState, rootState: RootState): number;
};
export const getters: GetterTree<WishState, RootState> & Getters = {
  getWishListItems: (state) => {
    return state.items;
  },
  getWishListSize: (state) => {
    return state.items.length;
  },

  getWishListTotalAmount:(state)=>{
      return state.items.reduce((a,b)=> {
          return a + ((b.variantPrice) *b.quantity)},0)
  }
};