
import {IonContent,IonPage,IonRouterOutlet,IonIcon,IonRange,
    IonToolbar,IonButtons,IonButton,IonMenuButton,IonHeader,IonItem,IonList,
    IonMenu,popoverController } from '@ionic/vue';
import {cloudUploadOutline,ellipsisVerticalOutline,personOutline,cartOutline} from "ionicons/icons";
import {useStore} from '@/store';
import {defineComponent,ref} from 'vue';
import {ToastNotify} from '@/lib/toast-notify';
import {CartActionTypes} from '@/store/modules/cart/action-types';
import QuickLinks from '@/views/QuickLinks.vue'
const store = useStore();

import $ from "jquery";

export default defineComponent({
    name: 'ava-mainlayout',
    components: {
        IonContent,
        IonPage,
        IonRouterOutlet,
        IonIcon,
        IonButtons,
        IonButton,
        IonRange,
        IonToolbar,
        IonMenuButton,
        IonHeader,
        IonItem,
        IonList,
        IonMenu
    },
    setup() {
        const collections = ref([]);
        const activeOrder = ref();
        const priceWithTaxMax = ref(0);
        const priceWithTaxMin = ref(0);
        const activeCustomer = ref();
        const topSellers = ref(Array < any > ());

        function allTopSellers() {
            const perChunk = 4 // items per chunk    

            const inputArray: any[] = [...store.getters.getBestSellers]

            const result: Array < any >= inputArray.reduce((resultArray: Array < any > , item: any, index: any) => {
                const chunkIndex = Math.floor(index / perChunk)

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [] // start a new chunk
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, [])
            topSellers.value = result;
        }

        function allCollections() {
            collections.value = [...store.getters.getCollections];
        }

        function getActiveCustomer() {
            activeCustomer.value = store.getters.getActiveCustomer;
        }

        function getActiveOrder() {
            activeOrder.value = store.getters.getActiveOrder;
        }

        function getPriceRange() {
            // const min = Number(store.getters?.getPriceWithTaxMin?.toString().slice(0, -2));
            // const max = Number(store.getters?.getPriceWithTaxMax?.toString().slice(0, -2));
            priceWithTaxMax.value = 0;
            priceWithTaxMin.value = 0;
        }

        function openSearch() {
            $(".search-overlay").addClass("open"),
                $('.search-overlay > form > input[type="search"]').focus();
        }
        function closeSearch() {
         const a = $(".search-overlay, .search-overlay .search-overlay-close");
        a.on('click', function(event) {
                event.target != this && "search-overlay-close" != event.target.className && 32 != event.keyCode || $(this).removeClass("open");
            });
        }
        store.subscribe((mutation) => {
            if (mutation.type == "PUSH_PRODUCT_CART" || mutation.type == "SET_ACTIVE_ORDER" || mutation.type == "SET_CART_ITEMS" || mutation.type == "RESET_ACTIVE_ORDER") {
                getActiveOrder();
            }
        });
        store.subscribe((mutation) => {
            if (mutation.type == "SET_ACTIVE_CUSTOMER") {
                getActiveCustomer();
            }
        });
        store.subscribe((mutation) => {
            if (mutation.type == "SET_PRICE_RANGE") {
                getPriceRange();
            }
        });
        allCollections()
        allTopSellers()
        getActiveOrder()
        getActiveCustomer()
        return {
            cloudUploadOutline,
            collections,
            topSellers,
            activeOrder,
            activeCustomer,
            getActiveOrder,
            priceWithTaxMin,
            priceWithTaxMax,
            openSearch,
            closeSearch,
            ellipsisVerticalOutline,
            personOutline,cartOutline,
            fullYear: new Date().getFullYear(),
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },

    },

    data() {
        return {
            rangeDualKnobs: {
                value: {
                    lower: 0,
                    upper: 0
                }
            },
            amountTextRange: ""
        }
    },
    mounted() {
        // Sets initial value for dual-knob ion-range
        // this.rangeDualKnobs.value = { lower: this.priceWithTaxMin, upper: this.priceWithTaxMax };
    },
    methods: {
        async openPopover(ev: Event) {
            const popover = await popoverController
                .create({
                    component: QuickLinks,
                    cssClass: 'my-custom-class',
                    event: ev,
                    translucent: true
                })
            await popover.present();

            const {
                role
            } = await popover.onDidDismiss();
            console.log('onDidDismiss resolved with role', role);
        },
        inc(quantity: number, lineId: any) {
            const newqnty = ++quantity;
            this.amendOrderLine(newqnty, lineId)
        },
        dec(quantity: number, lineId: any) {
            const newqnty = --quantity; // -= amt;
            this.amendOrderLine(newqnty, lineId)
        },
        amendOrderLine(newqnty: number, lineId: any) {
            store.dispatch(CartActionTypes.ADJUST_ORDER_LINE, {
                lineId: lineId,
                quantity: newqnty,
            }).then(() => {
                this.getActiveOrder();
            }).catch(err => {
                new ToastNotify({
                    title: 'Add To Cart',
                    description: err
                }, 'danger')
            });
        },
        removeOrderLine(lineId: any) {
            store.dispatch(CartActionTypes.REMOVE_ORDER_LINE, {
                lineId: lineId,
            }).then(() => {
                this.activeOrder = {};
                new ToastNotify({
                    title: 'Item Removed',
                    description: 'Item Has Been Removed from your Cart'
                }, 'danger')
            }).catch(err => {
                new ToastNotify({
                    title: 'Add To Cart',
                    description: err
                }, 'danger')
            });
        },
        priceRangeChange(e: any) {
            if (e.detail.value.lower) {
                this.amountTextRange = "KES" + e.detail.value.lower + " - KES" + e.detail.value.upper;
            } else {
                this.amountTextRange = "KES" + 0 + " - KES" + 0;
            }
        },
    },
});
