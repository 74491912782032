export enum CoreActionTypes{
GET_BANNERS='GET_BANNERS',
GET_ACTIVE_PROMOTIONS='GET_ACTIVE_PROMOTIONS',
GET_ADJUSTMENT_OPERATIONS='GET_ADJUSTMENT_OPERATIONS',
SHOW_NOTIFICATION='SHOW_NOTIFICATION',
FETCHING_PRODUCTS='FETCHING_PRODUCTS',
FETCHING_COLLECTIONS='FETCHING_COLLECTIONS',
FETCHING_ACTIVE_CUSTOMER='FETCHING_ACTIVE_CUSTOMER',
USER_AUTHENTICATING='USER_AUTHENTICATING',
USER_CREATING_PRESCRIPTION='USER_CREATING_PRESCRIPTION',
ACTIVE_PRODUCT='ACTIVE_PRODUCT',
SEARCH_PRODUCT='SEARCH_PRODUCT',
PRICE_RANGE='PRICE_RANGE',
}