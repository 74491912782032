export enum CheckoutActionTypes {
  GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS",
  SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",
  GET_ELIGIBLE_SHIPPING_METHODS = "GET_ELIGIBLE_SHIPPING_METHODS",
  SET_CUSTOMER_FOR_ORDER = "SET_CUSTOMER_FOR_ORDER",
  SET_ORDER_BILLING_ADDRESS = "SET_ORDER_BILLING_ADDRESS",
  SET_ORDER_SHIPPING_ADDRESS = "SET_ORDER_SHIPPING_ADDRESS",
  SET_ORDER_SHIPPING_METHOD = "SET_ORDER_SHIPPING_METHOD",
  ADD_PAYMENT_TO_ORDER = "ADD_PAYMENT_TO_ORDER",
  GET_NEXT_ORDER_STATES = "GET_NEXT_ORDER_STATES",
  TRANSITION_ORDER_TO_STATE = "TRANSITION_ORDER_TO_STATE",
  GET_IFRAME = "GET_IFRAME",
}

