
export type CheckoutState = {
  paymentMethods: any[];
  shippingMethods: any[];
  selectedpaymentMethod: any;
}

export const state: CheckoutState = {
  paymentMethods: [],
  shippingMethods: [],
  selectedpaymentMethod:null,
};
