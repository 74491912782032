
import {IonItem,IonList} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ava-quicklinks',
    components: {
        // IonContent,
        IonItem,
        IonList
    }
});
