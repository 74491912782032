export enum CartActionTypes{
    CHECK_OUT='CHECK_OUT',
    ADD_PRODUCT_TO_CART='ADD_PRODUCT_TO_CART',
    ADJUST_ORDER_LINE='ADJUST_ORDER_LINE',
    REMOVE_ORDER_LINE='REMOVE_ORDER_LINE',
    GET_ACTIVE_ORDER='GET_ACTIVE_ORDER',
    GET_ORDER_BY_CODE='GET_ORDER_BY_CODE',
    C_SET_ACTIVE_ORDER='C_SET_ACTIVE_ORDER',
    SELECT_SHIPPING_METHOD='SELECT_SHIPPING_METHOD',
    FETCHED_SHIPPING_METHODS='FETCHED_SHIPPING_METHODS',
    DELETE_FROM_CART='DELETE_FROM_CART',
    APPLY_COUPON_CODE='APPLY_COUPON_CODE',
    SET_CUSTOMER_FOR_ORDER='SET_CUSTOMER_FOR_ORDER',
    CUSTOMER_SUBMIT_PRESCRIPTION='CUSTOMER_SUBMIT_PRESCRIPTION',
    SUBMIT_PRESCRIPTION='SUBMIT_PRESCRIPTION',
}