import {
    Store as VuexStore,
    CommitOptions,
    DispatchOptions,
    Module,
  } from 'vuex';

  import { RootState } from '@/store';
  import type { CoreState } from './state';
  import { state } from './state';
  import { getters, Getters } from './getters';
  import { mutations, Mutations } from './mutations';
  import { actions, Actions } from './actions';

  export { CoreState as CoreState };
  
export type CoreStore<S = CoreState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'>
& {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
};

  export const store: Module<CoreState, RootState> = {
    state,
    mutations,
    getters,
    actions
  };