

export enum ProductsActionTypes {
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  ADD_PRODUCT_TO_VIEWED = 'ADD_PRODUCT_TO_VIEWED',
  FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID',
  FETCH_PRODUCT_BY_SLUG = 'FETCH_PRODUCT_BY_SLUG',
  FETCH_TOPRATED_PRODUCTS = 'FETCH_TOPRATED_PRODUCTS',
  FETCH_TOPSELLERS = 'FETCH_TOPSELLERS',
  FETCH_LATEST_PRODUCTS = 'FETCH_LATEST_PRODUCTS',
  GET_PRODUCTS_BY_COLLECTION = 'GET_PRODUCTS_BY_COLLECTION',
  GET_PRODUCTS_BY_BRAND = 'GET_PRODUCTS_BY_BRAND',
  CHECK_AVAILABILITY = 'CHECK_AVAILABILITY',
  SUBMIT_PRODUCT_REVIEW = 'SUBMIT_PRODUCT_REVIEW'
}
