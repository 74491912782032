import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { CheckoutState } from './state';
import { Mutations } from './mutations';
import { CheckoutActionTypes } from './action-types';
import { apolloClient } from '@/main';
import { ADD_PAYMENT_TO_ORDER, SET_CUSTOMER_FOR_ORDER, SET_ORDER_BILLING_ADDRESS, SET_ORDER_SHIPPING_ADDRESS, SET_ORDER_SHIPPING_METHOD, TRANSITION_ORDER_TO_STATE } from '@/grapql/mutations';
import { GET_ELIGIBLE_PAYMENT_METHODS, GET_ELIGIBLE_SHIPPING_METHODS, GET_IFRAME, GET_NEXT_ORDER_STATES } from '@/grapql/queries';
import { CheckoutMutationTypes } from './mutation-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<CheckoutState,  RootState>, 'commit'>

export interface Actions {
  [CheckoutActionTypes.GET_PAYMENT_METHODS]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<boolean>;
  [CheckoutActionTypes.SET_PAYMENT_METHOD]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.GET_ELIGIBLE_SHIPPING_METHODS]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.SET_CUSTOMER_FOR_ORDER]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.SET_ORDER_BILLING_ADDRESS]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.SET_ORDER_SHIPPING_ADDRESS]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.SET_ORDER_SHIPPING_METHOD]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.ADD_PAYMENT_TO_ORDER]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.GET_NEXT_ORDER_STATES]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.TRANSITION_ORDER_TO_STATE]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [CheckoutActionTypes.GET_IFRAME]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;

}

export const actions: ActionTree<CheckoutState, RootState> & Actions = {
  async [CheckoutActionTypes.GET_PAYMENT_METHODS]({ commit }) {
    const { data } = await apolloClient.query({ query: GET_ELIGIBLE_PAYMENT_METHODS})
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.eligiblePaymentMethods.length>0) {
          commit(CheckoutMutationTypes.STORE_PAYMENT_METHODS, data.eligiblePaymentMethods);
          return res(data.eligiblePaymentMethods);
        } else {
        return rej(data.eligiblePaymentMethods.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.GET_ELIGIBLE_SHIPPING_METHODS]({ commit }) {
    const { data } = await apolloClient.query({ query: GET_ELIGIBLE_SHIPPING_METHODS})
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.eligibleShippingMethods.length>0) {
          commit(CheckoutMutationTypes.SET_ELIGIBLE_SHIPPING_METHODS, data.eligibleShippingMethods);
          return res(data.eligibleShippingMethods);
        } else {
        return rej(data.eligibleShippingMethods.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.SET_PAYMENT_METHOD]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: ADD_PAYMENT_TO_ORDER, variables: { addPaymentToOrderInput: { method: payload.method, metadata: payload.metadata } }})
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.addPaymentToOrder["__typename"] =="Order") {
          return res(data.addPaymentToOrder);
        } else {
        return rej(data.addPaymentToOrder.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.ADD_PAYMENT_TO_ORDER]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: ADD_PAYMENT_TO_ORDER, variables: { addPaymentToOrderInput: {method: payload.method, metadata:{automaticCapture:false}} } })
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.addPaymentToOrder["__typename"] =="Order") {
          return res(data.addPaymentToOrder);
        } else {
        return rej(data.addPaymentToOrder.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.SET_CUSTOMER_FOR_ORDER]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: SET_CUSTOMER_FOR_ORDER, variables: { setCustomerForOrderInput:{...payload.input}} })
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.setCustomerForOrder["__typename"] =="Order") {
          return res(data.setCustomerForOrder);
        } else {
        return rej(data.setCustomerForOrder.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.SET_ORDER_BILLING_ADDRESS]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: SET_ORDER_BILLING_ADDRESS, variables: { setOrderBillingAddressInput: {...payload.input} } })
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.setOrderBillingAddress["__typename"] =="Order") {
          return res(data.setOrderBillingAddress);
        } else {
        return rej(data.setOrderBillingAddress.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.SET_ORDER_SHIPPING_ADDRESS]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: SET_ORDER_SHIPPING_ADDRESS, variables: { setOrderShippingAddressInput:{...payload.input}} })
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.setOrderShippingAddress["__typename"] =="Order") {
          return res(data.setOrderShippingAddress);
        } else {
        return rej(data.setOrderShippingAddress.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.SET_ORDER_SHIPPING_METHOD]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: SET_ORDER_SHIPPING_METHOD, variables: { setOrderShippingMethodShippingMethodId: payload.shippingMethodId} })
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.setOrderShippingMethod["__typename"] =="Order") {
          return res(data.setOrderShippingMethod);
        } else {
        return rej(data.setOrderShippingMethod.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.TRANSITION_ORDER_TO_STATE]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: TRANSITION_ORDER_TO_STATE, variables: { transitionOrderToStateState: payload.state} })
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.transitionOrderToState["__typename"] =="Order") {
          return res(data.transitionOrderToState);
        } else {
        return rej(data.transitionOrderToState.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.GET_NEXT_ORDER_STATES]() {
    const { data } = await apolloClient.query({ query: GET_NEXT_ORDER_STATES})
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.nextOrderStates.length>0) {
          return res(data.nextOrderStates);
        } else {
        return rej(data.nextOrderStates.message)

        }
      }, 500);
    });
  },
  async [CheckoutActionTypes.GET_IFRAME]({ commit }, payload: any) {
    const { data } = await apolloClient.query({ query: GET_IFRAME, variables:{getIframeIntput:{...payload.iframeIntput}}})
    return new Promise((res) => {
      setTimeout(() => {
        if(data?.getIframe["__typename"] =="PesapalIframe") {
          return res(data.getIframe);
        } else {
        return res(data.getIframe)

        }
      }, 500);
    });
  },
};
