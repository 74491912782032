export enum AuthActionTypes{
    LOG_IN="LOG_IN",
    GET_ACTIVE_CUSTOMER="GET_ACTIVE_CUSTOMER",
    UPDATE_ACTIVE_CUSTOMER="UPDATE_ACTIVE_CUSTOMER",
    LOG_OUT="LOG_OUT",
    REGISTER_CUSTOMER_ACCOUNT="REGISTER_CUSTOMER_ACCOUNT",
    VERIFY_CUSTOMER_ACCOUNT="VERIFY_CUSTOMER_ACCOUNT",
    UPDATE_CUSTOMER="UPDATE_CUSTOMER",
    CREATE_CUSTOMER_ADDRESS="CREATE_CUSTOMER_ADDRESS",
    UPDATE_CUSTOMER_ADDRESS="UPDATE_CUSTOMER_ADDRESS",
    DELETE_CUSTOMER_ADDRESS="DELETE_CUSTOMER_ADDRESS",
    REFRESH_CUSTOMER_VERIFICATION="REFRESH_CUSTOMER_VERIFICATION",
    UPDATE_CUSTOMER_PASSWORD="UPDATE_CUSTOMER_PASSWORD",
    REQUEST_UPDATE_CUSTOMER_EMAIL_ADDRESS="REQUEST_UPDATE_CUSTOMER_EMAIL_ADDRESS",
    REQUEST_PASSWORD_RESET="REQUEST_PASSWORD_RESET",
    RESET_PASSWORD="RESET_PASSWORD",
}