import { MutationTree } from 'vuex';

import { Collections } from '@/@types';

import { CollectionsState } from './state';
import { CollectionsMutationTypes } from './mutation-types';

export type Mutations<S = CollectionsState> = {
  [CollectionsMutationTypes.SET_COLLECTIONS](state: S, payload: Collections): void;
}

export const mutations: MutationTree<CollectionsState> & Mutations = {
  [CollectionsMutationTypes.SET_COLLECTIONS](state: CollectionsState, collections: Collections) {
    collections.forEach((collection: any) => {
      if (collection) {
        collection.assets.forEach((asset: any) => {
          const replaced =asset.source.replace(/\\/g, "/");
          asset.source=replaced;
        })
      }
    });
    state.collections = [...collections];
  },
};
