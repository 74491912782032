import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { Mutations } from "./mutations";
import { ProductsMutationTypes } from "./mutation-types";
import { ProductsActionTypes } from "./action-types";
import { watchEffect,watch } from "vue";
import { CoreActionTypes } from '../core/action-types';
import { useStore } from "@/store";
import { GET_AVAILABILITY_STATUS, GET_LATEST_PRODUCTS, GET_PRODUCT, GET_PRODUCTS, GET_PRODUCTS_BY_BRAND,
  GET_PRODUCTS_BY_COLLECTION, GET_PRODUCT_SLUG, GET_TOP_RATED_PRODUCTS, GET_TOP_SELLER_PRODUCTS } from "@/grapql/queries";
import { apolloClient } from "@/main";
import { SUBMIT_PRODUCT_REVIEW } from '../../../grapql/mutations';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ProductsActionTypes.FETCH_PRODUCTS]({commit}: AugmentedActionContext): Promise<any>;
  [ProductsActionTypes.ADD_PRODUCT_TO_VIEWED]({commit}: AugmentedActionContext,product: any): Promise<any>;
  [ProductsActionTypes.FETCH_TOPRATED_PRODUCTS]({commit}: AugmentedActionContext): Promise<any>;
  [ProductsActionTypes.FETCH_TOPSELLERS]({commit}: AugmentedActionContext): Promise<any>;
  [ProductsActionTypes.FETCH_LATEST_PRODUCTS]({commit}: AugmentedActionContext): Promise<any>;
  [ProductsActionTypes.CHECK_AVAILABILITY]({commit}: AugmentedActionContext, productId: any): Promise<boolean>;
  [ProductsActionTypes.FETCH_PRODUCT_BY_ID]({commit}: AugmentedActionContext, productId: any): Promise<any>;
  [ProductsActionTypes.FETCH_PRODUCT_BY_SLUG]({commit}: AugmentedActionContext, productSlug: any ): Promise<any>;
  [ProductsActionTypes.GET_PRODUCTS_BY_COLLECTION]({commit}: AugmentedActionContext, collectionSlug: any ): Promise<any>;
  [ProductsActionTypes.GET_PRODUCTS_BY_BRAND]({commit}: AugmentedActionContext, collectionSlug: any ): Promise<any>;
  [ProductsActionTypes.SUBMIT_PRODUCT_REVIEW]({commit}: AugmentedActionContext, payload: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProductsActionTypes.FETCH_PRODUCTS]({ commit }) {
    const store = useStore();
    const { data,loading,errors } = await apolloClient.query({query: GET_PRODUCTS,variables:{productsOptions:{take:20}}})  

    store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,true)
    watch(() => loading, (currentState) => {
      if(!currentState) {
        store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,false)
      }
   })
     return new Promise((res,rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data) {
            const products =data.products.items as []
            commit(ProductsMutationTypes.SET_PRODUCTS, products);            
            return res(products)
          }
        })
        return rej(errors);
      }, 500);
    });
  },
  async [ProductsActionTypes.ADD_PRODUCT_TO_VIEWED]({ commit }, payload: any) {
         return new Promise((res,rej) => {
      setTimeout(() => {
        watchEffect(() => {    
            commit(ProductsMutationTypes.SET_PRODUCT_TO_VIEWED, payload.product);            
            return res(payload.product)
          })
        return rej(null);
      }, 500);
    });
  },
  async [ProductsActionTypes.FETCH_TOPRATED_PRODUCTS]({ commit }) {
    const store = useStore();
    const { data,loading,errors } = await apolloClient.query({query: GET_TOP_RATED_PRODUCTS, variables:{productsOptions:{take:20}}})  

    store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,true)
    watch(() => loading, (currentState) => {
      if(!currentState) {
        store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,false)
      }
   })
     return new Promise((res,rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data) {
            const products =data.topRatedProducts as []
            commit(ProductsMutationTypes.SET_TOPRATED_PRODUCTS, products);            
            return res(products)
          }
        })
        return rej(errors);
      }, 500);
    });
  },  
  async [ProductsActionTypes.GET_PRODUCTS_BY_COLLECTION]({ commit }, payload: any) {
    const { data } = await apolloClient.query({query: GET_PRODUCTS_BY_COLLECTION, variables:{collectionSlug:payload.category,productVariantsOptions:payload.options}})  
     return new Promise((res,rej) => {  
      setTimeout(() => {
        if(data?.collection["__typename"] =="Collection") {
          
          if(payload.category=='featured-products') {
            commit(ProductsMutationTypes.SET_FEATURED_PRODUCTS, data.collection.productVariants.items);
          }  else if(payload.category=='best-sellers') {
            commit(ProductsMutationTypes.SET_BEST_SELLERS, data.collection.productVariants.items);
          } else if(payload.category=='new-arrivals') {
            commit(ProductsMutationTypes.SET_NEW_ARRIVALS, data.collection.productVariants.items);
          }
          return res(data.collection);
        } else {
        return rej(data.collection.message)
        }
      }, 500);
    });
  },  
  async [ProductsActionTypes.GET_PRODUCTS_BY_BRAND]({ commit }, payload: any) {
    const { data } = await apolloClient.query({query: GET_PRODUCTS_BY_BRAND, variables:{productsByBrandFacetCode:payload.brandCode,productsByBrandOptions:payload.options}})  
     return new Promise((res,rej) => {  
      setTimeout(() => {
        if(data?.productsByBrand["__typename"] =="ProductList") {
          return res(data.productsByBrand);
        } else {
        return rej(data.productsByBrand.message)
        }
      }, 500);
    });
  },  
  async [ProductsActionTypes.FETCH_TOPSELLERS]({ commit }) {
    const store = useStore();
    const { data,loading,errors } = await apolloClient.query({query: GET_TOP_SELLER_PRODUCTS,variables:{productsOptions:{take:20}}})  

    store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,true)
    watch(() => loading, (currentState) => {
      if(!currentState) {
        store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,false)
      }
   })
    return new Promise((res,rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data) {
            const products =data.topSellerProducts as []
            commit(ProductsMutationTypes.SET_BEST_SELLERS, products);
            return res(products)
          }
        })
        return rej(errors);
      }, 500);
    });
  },
  async [ProductsActionTypes.FETCH_LATEST_PRODUCTS]({ commit }) {
    const store = useStore();
    const { data,loading,errors } = await apolloClient.query({query: GET_LATEST_PRODUCTS,variables:{productsOptions:{take:20}}})  

    store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,true)
    watch(() => loading, (currentState) => {
      if(!currentState) {
        store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,false)
      }
   })
     return new Promise((res,rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data) {
            const products =data.latestProducts as []
            commit(ProductsMutationTypes.SET_NEW_ARRIVALS, products);
            return res(products)
          }
        })
        return rej(errors);
      }, 500);
    });
  }, 
  async [ProductsActionTypes.CHECK_AVAILABILITY]({ commit}, productId: any ) {
    const store = useStore();
    const { data,loading,errors } = await apolloClient.query({query: GET_AVAILABILITY_STATUS,variables:{productId:productId}})  

    store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,true)
    watch(() => loading, (currentState) => {
      if(!currentState) {
        store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,false)
      }
   })
     return new Promise((res,rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data) {
            const products =data.latestProducts as []
            commit(ProductsMutationTypes.SET_CHECK_AVAILABILITY, products);
            return res(true)
          }
        })
        return rej(errors);
      }, 500);
    });
  },
  async [ProductsActionTypes.FETCH_PRODUCT_BY_ID]({ commit}, productId: any ) {
    const store = useStore();
    const { data,loading,errors } = await apolloClient.query({query: GET_PRODUCT,variables:{productId:productId}})  

    store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,true)
    watch(() => loading, (currentState) => {
      if(!currentState) {
        store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,false)
      }
   })
     return new Promise((res,rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data) {
            const products =data.product as unknown
            commit(ProductsMutationTypes.SET_PRODUCT_BY_ID, products);
            return res(res)
          }
        })
        return rej(errors)
      }, 500);
    });
  },
  async [ProductsActionTypes.FETCH_PRODUCT_BY_SLUG]({ commit }, productSlug: any) {
    const { data } = await apolloClient.query({query: GET_PRODUCT_SLUG,variables:{productSlug: productSlug}})  
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.product["__typename"] =="Product") {
          commit(ProductsMutationTypes.SET_PRODUCT_BY_SLUG, data?.product);
          return res(data.product);
        } else {
        return rej(data.product.message)
        }
      }, 500);
    });
  },
  // async [ProductsActionTypes.FETCH_PRODUCT_BY_SLUG]({ commit}, productSlug: any ) {
  //   const store = useStore();
  //   const { data,loading,errors } = await apolloClient.query({query: GET_PRODUCT_SLUG,variables:{productSlug:productSlug}})  

  //   store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,true)
  //   watch(() => loading, (currentState) => {
  //     if(!currentState) {
  //       store.dispatch(CoreActionTypes.FETCHING_PRODUCTS,false)
  //     }
  //  })
  //    return new Promise((res,rej) => {
  //     setTimeout(() => {
  //       let _product={}
  //       watchEffect(() => {
  //         if(data) {
  //           _product =data.product as {}
  //           commit(ProductsMutationTypes.SET_PRODUCT_BY_SLUG, _product);
  //           return res(res)
  //         }
  //       })
  //       return _product;
  //     }, 500);
  //   });
  // },  
  async [ProductsActionTypes.SUBMIT_PRODUCT_REVIEW]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({ mutation: SUBMIT_PRODUCT_REVIEW, variables: { submitProductReviewInput: payload.input} })
    return new Promise((res, rej) => {
      setTimeout(() => {
        if(data?.submitProductReview["__typename"] =="Prescription") {
          return res(data.submitProductReview);
        } else {
        return rej(data.submitProductReview.message)

        }
      }, 500);
    });
  },
};
