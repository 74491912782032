import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';

export interface Getters {
  getProducts(state: State):  [];
  getFeaturedProducts(state: State): [];
  getBestSellers(state: State): [];
  getNewArrivals(state: State): [];
  getTopRated(state: State): [];
  getSelectedProduct(state: State): unknown;
  getViewedProducts(state: State): any[];
  getActiveProduct(state: State): any;
}

export const getters: GetterTree<State, RootState>  & Getters = {
  getProducts: (state) => state.products,
  getFeaturedProducts: (state) => state.featuredProducts,
  getBestSellers: (state) => state.bestSellers,
  getNewArrivals: (state) => state.newArrivals,
  getTopRated: (state) => state.topRated,
  getSelectedProduct: (state) => state.selectedProduct,
  getViewedProducts: (state) => state.viewedProducts,
  getActiveProduct: (state) => state.activeProduct,
};
