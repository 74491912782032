
export type State = {
  products: [];
  featuredProducts: [];
  topRated: [];
  bestSellers: [];
  newArrivals: [];
  selectedProduct: any;
  activeProduct: any;
  viewedProducts: any[];
}
export const state: State = {
  products: [],
  featuredProducts:[],
  topRated:[],
  bestSellers:[],
  newArrivals:[],
  selectedProduct:{},
  activeProduct:{},
  viewedProducts: []
};
