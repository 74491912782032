import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { CollectionsState } from './state';
import { Collections } from '@/@types';

export type Getters = {
  getCollections(state: CollectionsState): Collections | [];
}

export const getters: GetterTree<CollectionsState, RootState> & Getters = {
  getCollections: (state) => state.collections,
};
