/* disble-eslint */
import { createLogger, createStore } from "vuex";

import { store as collections,CollectionsStore, CollectionsState} from "@/store/modules/collections";
import { store as products,ProductsStore,State as ProductsState} from "@/store/modules/products";
import { store as cart, CartStore, CartState } from "@/store/modules/cart";
import {store as WishList,WishListStore,WishState} from "@/store/modules/wishList";

import {store as authUser,UserStore,State} from "@/store/modules/auth";
import {store as core,CoreStore,CoreState} from "@/store/modules/core";
import {store as checkout,CheckoutStore,CheckoutState} from "@/store/modules/checkout";

import createPersistedState from "vuex-persistedstate";

import SecureLS from "secure-ls";


const ls = new SecureLS({ isCompression: false });

export type RootState = {
  collections: CollectionsState;
  products: ProductsState;
  cart: CartState;
  WishList: WishState;
  authUser: State;
  core: CoreState;
  checkout: CheckoutState;
};

export type Store = CollectionsStore<Pick<RootState, "collections">>&
  ProductsStore<Pick<RootState, "products">> &
  CartStore<Pick<RootState, "cart">> &
  WishListStore<Pick<RootState, "WishList">>&
  UserStore<Pick<RootState, "authUser">>&
  CoreStore<Pick<RootState, "core">>&
  CheckoutStore<Pick<RootState, "checkout">>;

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== "production";
const plugins = debug ? [createLogger({})] : [];

// Plug in session storage based persistence
//TODO: Enable Encryption
plugins.push(
  createPersistedState({
    key: "ava-express",
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
    // storage: window.sessionStorage,
  })
);

export const store = createStore({
  plugins,
  modules: {
    collections,
    products,
    cart,
    WishList,
    authUser,
    core,
    checkout
  },
});

export function useStore(): Store {
  return store as Store;
}
