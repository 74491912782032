
import { createToast } from "mosha-vue-toastify"
import CustomizedContent  from "@/views/CustomizedContent.vue"
export const _toastNotifyTypes: any = {
    "success": { position: "top-right", type: "default", transition: 'bounce',timeout: 5000},
    "default": { position: "top-right", type: "default", transition: 'bounce',timeout: 8000 },
    "info": { position: "top-right", type: "default", transition: 'bounce',timeout: 5000 },
    "warning": { position: "top-right", type: "default", transition: 'bounce',timeout: 5000 },
    "danger": { position: "top-right", type: "default", transition: 'bounce',timeout: 5000 },
    "error": { position: "top-right", type: "danger", transition: 'bounce',timeout: 8000 },
}
export const _toastNotifyTitles: any = {
    "Error":'System Error',
    "toggleFavorite":'Add Product To Wishlist',
    "networkError":'Bad Request',
    "activeOrder":'Get Active Order',
    "activeCustomer":'Get Active Customer',
    "collection":'Fetch Category',
    "collections":'Fetch Categories',
    "me":'Fetch User',
    "order":'',
    "product":'Fetch Product',
    "products":'Fetch Products',
    "search":'Search',
    "eligibleShippingMethods":'Get Eligible Shipping Methods',
    "login":'Login',
    "logout":'Logout',
    "registerCustomerAccount":'Register Customer Account',
    "resetPassword":'Reset Password',
    "verifyCustomerAccount":'Verify Customer Account',
    "submitPrescription":'Submit Prescription',
    "addItemToOrder":'Add Item To Order',
    "addPaymentToOrder":'Add Payment Method To Order',
    "adjustOrderLine":'Adjust Order Item',
    "applyCouponCode":'Apply Coupon Code',
    "deleteCustomerAddress":'Delete Customer Address',
    "removeOrderLine":'Remove Order Item',
    "setOrderShippingMethod":'Set Order Shipping Method',
}
export class ToastNotify {
    constructor(message: any, option: string) {
        const options = _toastNotifyTypes[option];
        createToast(message, options)
    }
}
export class NotificationService {
    constructor() {
        // const options = _toastNotifyTypes[option];
        // createToast(message, options)
        createToast(CustomizedContent,
            {            
            showIcon: false,
            position: 'top-right',
            transition: 'bounce',
            type: 'default',
            timeout: 5000
            })
    }
}