
export enum ProductsMutationTypes {
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCT_TO_VIEWED = 'SET_PRODUCT_TO_VIEWED',
  SET_TOPRATED_PRODUCTS = 'SET_TOPRATED_PRODUCTS',
  SET_FEATURED_PRODUCTS = 'SET_FEATURED_PRODUCTS',
  SET_NEW_ARRIVALS = 'SET_LATEST_PRODUCTS',
  SET_BEST_SELLERS = 'SET_TOPSELLERS',
  SET_CHECK_AVAILABILITY = 'SET_CHECK_AVAILABILITY',
  SET_PRODUCT_BY_ID = 'SET_PRODUCT_BY_ID',
  SET_PRODUCT_BY_SLUG = 'SET_PRODUCT_BY_SLUG'
}
