import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { CollectionsState } from './state';
import { Mutations } from './mutations';
import { CollectionsMutationTypes } from './mutation-types';
import { CollectionsActionTypes } from './action-types';
import { useResult,useQuery } from "@vue/apollo-composable";
import { watchEffect,watch } from 'vue';
import { CoreActionTypes } from '../core/action-types';
import { useStore } from "@/store";
import { GET_COLLECTIONS } from '@/grapql/queries';
import { apolloClient } from '@/main'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<CollectionsState, RootState>, 'commit'>

export interface Actions {
  [CollectionsActionTypes.FETCH_COLLECTIONS]({ commit }: AugmentedActionContext): Promise<boolean>;
}

export const actions: ActionTree<CollectionsState, RootState> & Actions = {
  async [CollectionsActionTypes.FETCH_COLLECTIONS]({ commit }) {
    
    const store = useStore();
    const { data,loading } = await apolloClient.query({query: GET_COLLECTIONS,variables:{collectionsOptions:{}}})  
    store.dispatch(CoreActionTypes.FETCHING_COLLECTIONS,true)
    watch(() => loading, (currentState) => {
      if(!currentState) {
        store.dispatch(CoreActionTypes.FETCHING_COLLECTIONS,false)
      }
   })
    return new Promise(() => {
      setTimeout(() => {
        watchEffect(() => {
          if(data) {
            const collections =data.collections.items as []
            commit(CollectionsMutationTypes.SET_COLLECTIONS, collections);
          }
        })

        return true;
      }, 500);
    });
  },
};
