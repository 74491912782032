import { MutationTree } from "vuex";

import { CoreMutationTypes } from "./mutation-types";
import { CoreState } from '@/store/modules/core';

export type Mutations<S = CoreState> = {
  [CoreMutationTypes.SET_NOTIFICATION](state: S, payload: any): void;
  [CoreMutationTypes.SET_FETCHING_ACTIVE_CUSTOMER](state: S, payload: any): void;
  [CoreMutationTypes.SET_FETCHING_PRODUCTS](state: S, payload: any): void;
  [CoreMutationTypes.SET_FETCHING_COLLECTIONS](state: S, payload: any): void;
  [CoreMutationTypes.SET_USER_AUTHENTICATING](state: S, payload: any): void;
  [CoreMutationTypes.SET_USER_CREATING_PRESCRIPTION](state: S, payload: any): void;
  [CoreMutationTypes.SET_PRICE_RANGE](state: S, payload: any): void;
};

export const mutations: MutationTree<CoreState> & Mutations = {

  [CoreMutationTypes.SET_NOTIFICATION](state: CoreState) {
    state.showNotification = false;
  },

  [CoreMutationTypes.SET_FETCHING_ACTIVE_CUSTOMER](state: CoreState, status: boolean) {
    state.fetchingActiveCutomer = status;
  },

  [CoreMutationTypes.SET_FETCHING_PRODUCTS](state: CoreState, status: boolean) {
    state.fetchingProducts = status;
  },

  [CoreMutationTypes.SET_FETCHING_COLLECTIONS](state: CoreState, status: boolean) {
    state.fetchingCollections = status;
  },

  [CoreMutationTypes.SET_USER_CREATING_PRESCRIPTION](state: CoreState, status: boolean) {
    state.userCreatingPrescription = status;
  },

  [CoreMutationTypes.SET_USER_AUTHENTICATING](state: CoreState, status: boolean) {
    state.userAUthenticating = status;
  },
  [CoreMutationTypes.SET_PRICE_RANGE](state: CoreState, range: any) {
    state.priceWithTaxMax = range.priceWithTaxMax;
    state.priceWithTaxMin = range.priceWithTaxMin;
  }
}


