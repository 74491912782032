
export type CartState = {
    cartItems: any[];
    activeOrder: any;
    shippingMethods: any[];
    selectedShippingMethod: any;
    checkoutStatus: CheckoutStatus;
  }

  export type CartProductsGetter = Omit<any, "id">;

  export type CheckoutStatus = "successful" | "failed" | null;

  export const state: CartState = {
    cartItems: [],
    activeOrder: undefined,
    shippingMethods: [],
    selectedShippingMethod: {},
    checkoutStatus: null
  };