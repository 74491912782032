import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";

import { CartState } from "./state";
import { Mutations } from "./mutations";
import { CartMutationTypes } from "./mutation-types";

import { CartActionTypes } from "./action-types";
import { useQuery, useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { watchEffect } from "vue";
import { apolloClient } from "@/main";
import {
  ADD_ITEM_TO_ORDER,
  ADJUST_ORDER_LINE,
  APPLY_COUPON_CODE,
  CUSTOMER_SUBMIT_PRESCRIPTION,
  REMOVE_ORDER_LINE,
  SET_CUSTOMER_FOR_ORDER,
  SUBMIT_PRESCRIPTION,
} from "@/grapql/mutations";
import { GET_ACTIVE_ORDER, GET_ORDER_BY_CODE } from "@/grapql/queries";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<CartState, RootState>, "commit">;

export interface Actions {
  // [CartActionTypes.CHECK_OUT]({commit,}: AugmentedActionContext): Promise<any>;
  [CartActionTypes.ADD_PRODUCT_TO_CART](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.ADJUST_ORDER_LINE](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.REMOVE_ORDER_LINE](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.GET_ACTIVE_ORDER](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.GET_ORDER_BY_CODE](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.C_SET_ACTIVE_ORDER](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.SELECT_SHIPPING_METHOD](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.FETCHED_SHIPPING_METHODS](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.DELETE_FROM_CART](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.APPLY_COUPON_CODE](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.SET_CUSTOMER_FOR_ORDER](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.CUSTOMER_SUBMIT_PRESCRIPTION](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
  [CartActionTypes.SUBMIT_PRESCRIPTION](
    { commit }: AugmentedActionContext,
    { payload: data }: any
  ): Promise<any>;
}

export const actions: ActionTree<CartState, RootState> & Actions = {
  async [CartActionTypes.ADD_PRODUCT_TO_CART]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({
      mutation: ADD_ITEM_TO_ORDER,
      variables: {
        addItemToOrderProductVariantId: payload.product.variants[0].id,
        addItemToOrderQuantity: payload.quantity,
      },
    });
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (data.addItemToOrder["__typename"] == "Order") {
          // const activeCustomer= data.activeCustomer
          commit(CartMutationTypes.PUSH_PRODUCT_CART, [
            ...data.addItemToOrder?.lines,
          ]);
          commit(CartMutationTypes.SET_ACTIVE_ORDER, data.addItemToOrder);
          return res(data.addItemToOrder);
        }
        return rej(data.addItemToOrder.message);
      }, 500);
    });
  },
  async [CartActionTypes.ADJUST_ORDER_LINE]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({
      mutation: ADJUST_ORDER_LINE,
      variables: {
        adjustOrderLineOrderLineId: payload.lineId,
        adjustOrderLineQuantity: payload.quantity,
      },
    });
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (data.adjustOrderLine["__typename"] == "Order") {
          commit(CartMutationTypes.PUSH_PRODUCT_CART, [
            ...data.adjustOrderLine?.lines,
          ]);
          commit(CartMutationTypes.SET_ACTIVE_ORDER, data.adjustOrderLine);
          return res(data.adjustOrderLine);
        }
        return rej(data.adjustOrderLine.message);
      }, 500);
    });
  },
  async [CartActionTypes.REMOVE_ORDER_LINE]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({
      mutation: REMOVE_ORDER_LINE,
      variables: { removeOrderLineOrderLineId: payload.lineId },
    });
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (data.removeOrderLine["__typename"] == "Order") {
          commit(CartMutationTypes.RESET_ACTIVE_ORDER, data.removeOrderLine);
          commit(CartMutationTypes.PUSH_PRODUCT_CART, [
            ...data.removeOrderLine?.lines,
          ]);
          commit(CartMutationTypes.SET_ACTIVE_ORDER, data.removeOrderLine);
          return res(data.removeOrderLine);
        }
        return rej(data.removeOrderLine.message);
      }, 500);
    });
  },
  async [CartActionTypes.APPLY_COUPON_CODE]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({
      mutation: APPLY_COUPON_CODE,
      variables: { applyCouponCodeCouponCode: payload.couponCode },
    });
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (data.applyCouponCode["__typename"] == "Order") {
          commit(CartMutationTypes.RESET_ACTIVE_ORDER, data.applyCouponCode);
          commit(CartMutationTypes.PUSH_PRODUCT_CART, [
            ...data.applyCouponCode?.lines,
          ]);
          commit(CartMutationTypes.SET_ACTIVE_ORDER, data.applyCouponCode);
          return res(data.applyCouponCode);
        } else {
          return rej(data.applyCouponCode.message);
        }
      }, 500);
    });
  },
  async [CartActionTypes.SET_CUSTOMER_FOR_ORDER]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({
      mutation: SET_CUSTOMER_FOR_ORDER,
      variables: { applyCouponCodeCouponCode: payload.couponCode },
    });
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (data.setCustomerForOrder["__typename"] == "Order") {
          commit(
            CartMutationTypes.RESET_ACTIVE_ORDER,
            data.setCustomerForOrder
          );
          commit(CartMutationTypes.PUSH_PRODUCT_CART, [
            ...data.setCustomerForOrder?.lines,
          ]);
          commit(CartMutationTypes.SET_ACTIVE_ORDER, data.setCustomerForOrder);
          return res(data.setCustomerForOrder);
        } else {
          return rej(data.setCustomerForOrder.message);
        }
      }, 500);
    });
  },
  async [CartActionTypes.CUSTOMER_SUBMIT_PRESCRIPTION](
    payload: any
  ) {
    const { data } = await apolloClient.mutate({
      mutation: CUSTOMER_SUBMIT_PRESCRIPTION,
      variables: { customerSubmitPrescriptionInput: payload.input },
    });
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (data.customerSubmitPrescription["__typename"] == "Prescription") {
          return res(data.customerSubmitPrescription);
        } else {
          return rej(data.customerSubmitPrescription.message);
        }
      }, 500);
    });
  },
  async [CartActionTypes.SUBMIT_PRESCRIPTION]({ commit }, payload: any) {
    const { data } = await apolloClient.mutate({
      mutation: SUBMIT_PRESCRIPTION,
      variables: { submitPrescriptionInput: payload.input },
    });
    return new Promise((res) => {
      setTimeout(() => {
        if (data.submitPrescription["__typename"] == "Prescription") {
          return res(data.submitPrescription);
        } else {
          return res(data.customerSubmitPrescription.message);
        }
      }, 500);
    });
  },
  async [CartActionTypes.GET_ACTIVE_ORDER]({ commit }) {
    const { data } = await apolloClient.query({ query: GET_ACTIVE_ORDER });
    return new Promise((res) => {
      setTimeout(() => {
        if (data.activeOrder && data.activeOrder["__typename"] == "Order") {
          commit(CartMutationTypes.PUSH_PRODUCT_CART, [
            ...data.activeOrder?.lines,
          ]);
          commit(CartMutationTypes.SET_ACTIVE_ORDER, data.activeOrder);
          return res(data.activeOrder);
        } else {
          return res(data.activeOrder);
        }
      }, 500);
    });
  },
  async [CartActionTypes.GET_ORDER_BY_CODE]({ commit }, payload: any) {
    const { data } = await apolloClient.query({
      query: GET_ORDER_BY_CODE,
      variables: { orderByCodeCode: payload.code },
    });
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (data.orderByCode && data.orderByCode["__typename"] == "Order") {
          commit(CartMutationTypes.PUSH_PRODUCT_CART, [
            ...data.orderByCode?.lines,
          ]);
          commit(CartMutationTypes.SET_ACTIVE_ORDER, data.orderByCode);
          return res(data.orderByCode);
        } else {
          commit(CartMutationTypes.SET_ACTIVE_ORDER, null);
          return rej(null);
        }
      }, 500);
    });
  },
  async [CartActionTypes.C_SET_ACTIVE_ORDER]({ commit }, payload: any) {
    return new Promise((res) => {
      setTimeout(() => {
        commit(CartMutationTypes.SET_ACTIVE_ORDER, payload);
        return res(true);
      }, 500);
    });
  },
  async [CartActionTypes.SELECT_SHIPPING_METHOD]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CartMutationTypes.SET_SELECT_SHIPPING_METHOD, payload);
        return true;
      }, 500);
    });
  },
  async [CartActionTypes.FETCHED_SHIPPING_METHODS]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CartMutationTypes.SET_FETCHED_SHIPPING_METHODS, payload);
        return true;
      }, 500);
    });
  },
  async [CartActionTypes.DELETE_FROM_CART]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(CartMutationTypes.UNSET_CART_ITEMS, payload);
        return true;
      }, 500);
    });
  },
};
