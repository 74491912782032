import { ActionTree, ActionContext } from "vuex";

import { RootState} from '@/store';

import { State } from "./state";
import { Mutations } from "./mutations";
import { AuthActionTypes } from "./action-types";

import { UserMutationTypes } from "./mutation-types";
import { watchEffect } from 'vue';
import { GET_ACTIVE_CUSTOMER } from "@/grapql/queries";
import { apolloClient } from "@/main";
import { CREATE_CUSTOMER_ADDRESS, DELETE_CUSTOMER_ADDRESS, LOGIN, LOG_OUT, REFRESH_CUSTOMER_VERIFICATION, REGISTER_CUSTOMER_ACCOUNT, REQUEST_PASSWORD_RESET, REQUEST_UPDATE_CUSTOMER_EMAIL_ADDRESS, RESET_PASSWORD, UPDATE_CUSTOMER, UPDATE_CUSTOMER_ADDRESS, UPDATE_CUSTOMER_PASSWORD, VERIFY_CUSTOMER_ACCOUNT } from "@/grapql/mutations";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [AuthActionTypes.LOG_IN]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [AuthActionTypes.UPDATE_ACTIVE_CUSTOMER]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [AuthActionTypes.GET_ACTIVE_CUSTOMER]({ commit }: AugmentedActionContext, { payload: data }: any): Promise<any>;
  [AuthActionTypes.LOG_OUT]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.REGISTER_CUSTOMER_ACCOUNT]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.VERIFY_CUSTOMER_ACCOUNT]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.UPDATE_CUSTOMER]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.CREATE_CUSTOMER_ADDRESS]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.UPDATE_CUSTOMER_ADDRESS]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.DELETE_CUSTOMER_ADDRESS]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.REFRESH_CUSTOMER_VERIFICATION]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.UPDATE_CUSTOMER_PASSWORD]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.REQUEST_UPDATE_CUSTOMER_EMAIL_ADDRESS]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.REQUEST_PASSWORD_RESET]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
  [AuthActionTypes.RESET_PASSWORD]({ commit }: AugmentedActionContext, payload: any): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [AuthActionTypes.LOG_IN]({ commit }, payload: any) {
    
    const { data } = await apolloClient.mutate({mutation: LOGIN,variables:{
      loginUsername: payload.username,
      loginPassword: payload.password,
      loginRememberMe: payload.remember
    }})
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.login["__typename"] =="CurrentUser") {
            commit(UserMutationTypes.LOGIN_USER, payload);
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.login)
          } else{
            return rej(data.login.message);
          }
        })
        return rej(data.login.message);
      }, 500);
    });
  },
  async [AuthActionTypes.UPDATE_ACTIVE_CUSTOMER]({ commit }, payload: any) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        commit(UserMutationTypes.SET_UPDATE_ACTIVE_CUSTOMER, payload);
        commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)      
      }, 500);
    });
  },
  async [AuthActionTypes.GET_ACTIVE_CUSTOMER]({ commit }) {
    const { data } = await apolloClient.query({query: GET_ACTIVE_CUSTOMER,variables:{collectionsOptions:{take:20}}})
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.activeCustomer && data.activeCustomer["__typename"] =="Customer") {
            const activeCustomer= data.activeCustomer
            commit(UserMutationTypes.SET_ACTIVE_CUSTOMER, activeCustomer);
            return res(data.activeCustomer)
          }else{
           return res(data.activeCustomer)
          }
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.VERIFY_CUSTOMER_ACCOUNT]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: VERIFY_CUSTOMER_ACCOUNT,variables:{verifyCustomerAccountToken:payload.tokenValue,verifyCustomerAccountPassword:payload.password}})
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.verifyCustomerAccount["__typename"] =="CurrentUser") {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.verifyCustomerAccount)
          } else{
            return rej(data.verifyCustomerAccount.message);
          }
        })
        return rej(data.verifyCustomerAccount.message);
      }, 500);
    });
  },
  async [AuthActionTypes.REGISTER_CUSTOMER_ACCOUNT]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: REGISTER_CUSTOMER_ACCOUNT,variables: { registerCustomerAccountInput: payload.registerCustomerAccountInput}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.registerCustomerAccount["__typename"] =="Success") {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.registerCustomerAccount)
          } else{
            return rej(data.registerCustomerAccount.message);
          }
          
        })
      
      }, 500);
    });
  },

  async [AuthActionTypes.LOG_OUT]({ commit }) {
    const { data } = await apolloClient.mutate({mutation: LOG_OUT})
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data?.logout.success) {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.logout)
          } else{
            return rej(data.logout.message);
          }
          
        })
      
      }, 500);
    });
  },

  async [AuthActionTypes.UPDATE_CUSTOMER]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: UPDATE_CUSTOMER,variables: { updateCustomerInput: payload.input}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.updateCustomer) {
            const activeCustomer= data.updateCustomer
            commit(UserMutationTypes.SET_ACTIVE_CUSTOMER, activeCustomer);
            return res(data.updateCustomer)
          } else{
            return rej(data.updateCustomer.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.CREATE_CUSTOMER_ADDRESS]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: CREATE_CUSTOMER_ADDRESS,variables: { createCustomerAddressInput: payload.input}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.createCustomerAddress) {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.createCustomerAddress)
          } else{
            return rej(data.createCustomerAddress.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.UPDATE_CUSTOMER_ADDRESS]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: UPDATE_CUSTOMER_ADDRESS,variables: { updateCustomerAddressInput: payload.input}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.updateCustomerAddress) {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.updateCustomerAddress)
          } else{
            return rej(data.updateCustomerAddress.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.DELETE_CUSTOMER_ADDRESS]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: DELETE_CUSTOMER_ADDRESS,variables: { deleteCustomerAddressId: payload.id}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
          if(data?.deleteCustomerAddress.success) {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.deleteCustomerAddress)
          } else{
            return rej(data.registerCustomerAccount.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.REFRESH_CUSTOMER_VERIFICATION]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: REFRESH_CUSTOMER_VERIFICATION,variables: { refreshCustomerVerificationEmailAddress: payload.emailAddress}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.refreshCustomerVerification["__typename"] =="Success") {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.refreshCustomerVerification)
          } else{
            return rej(data.refreshCustomerVerification.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.UPDATE_CUSTOMER_PASSWORD]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: UPDATE_CUSTOMER_PASSWORD,variables: { updateCustomerPasswordCurrentPassword: payload.currentPassword, updateCustomerPasswordNewPassword: payload.newPassword}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.updateCustomerPassword["__typename"] =="Success") {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.updateCustomerPassword)
          } else{
            return rej(data.updateCustomerPassword.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.REQUEST_UPDATE_CUSTOMER_EMAIL_ADDRESS]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: REQUEST_UPDATE_CUSTOMER_EMAIL_ADDRESS,variables: { requestUpdateCustomerEmailAddressPassword: payload.password,requestUpdateCustomerEmailAddressNewEmailAddress: payload.newEmailAddress}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.requestUpdateCustomerEmailAddress["__typename"] =="Success") {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.requestUpdateCustomerEmailAddress)
          } else{
            return rej(data.requestUpdateCustomerEmailAddress.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.REQUEST_PASSWORD_RESET]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: REQUEST_PASSWORD_RESET,variables: { requestPasswordResetEmailAddress: payload.emailAddress}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.requestPasswordReset["__typename"] =="Success") {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.requestPasswordReset)
          } else{
            return rej(data.requestPasswordReset.message);
          }
          
        })
      
      }, 500);
    });
  },
  async [AuthActionTypes.RESET_PASSWORD]({ commit },payload) {
    const { data } = await apolloClient.mutate({mutation: RESET_PASSWORD,variables: { resetPasswordToken: payload.token, resetPasswordPassword: payload.password}})  
   
    return new Promise((res, rej) => {
      setTimeout(() => {
        watchEffect(() => {
           if(data?.resetPassword["__typename"] =="CurrentUser") {
            commit(UserMutationTypes.RE_FECTH_ACTIVE_CUSTOMER, undefined)
            return res(data.resetPassword)
          } else{
            return rej(data.resetPassword.message);
          }
          
        })
      
      }, 500);
    });
  },
};
