import moment from "moment";
import numeral from 'numeral';
import { KES } from '@dinero.js/currencies';

export const GlobalFilters = {
    priceFormatting(value: string) {
        if (!value) return "";
        const len = value.toString().length;
        const position = len - 2;
        const output = [value.toString().slice(0, position), '.', value.toString().slice(position)].join('')
        return output.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },   
    formatDate(value: string) {
        if (value) {
            return moment(String(value)).format('MMM Do YYYY, h:mm:ss a')
        }
    },    
    formatDateOnly(value: string) {
        if (value) {
            return moment(String(value)).format('MMM Do YYYY')
        }
    },

    formatWord(word: string) {
        const _word =word.match(/[A-Z][a-z]+/g)as []
        return _word.join(" ");
    },
    priceVariantFormatting(variants: any[]) {
        const value =variants[0].priceWithTax;
        if (!value) return "";
        const len = value.toString().length;
        const position = len - 2;
        const output = [value.toString().slice(0, position), '.', value.toString().slice(position)].join('')
        return output.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    skuFormatting(variants: any[]) {
        const sku =variants[0].sku;
        return sku
    },
    availabilityFormatting(variants: any[]) {
        const stockLevel =variants[0].stockLevel;
        return stockLevel
    },
    brandFormatting(facetValues: any[]) {
        const facetvalue =facetValues.find(x=>x.facet.code=='brand');
        return facetvalue
    },
    productNameFormatting(str: string) {
        if (str.length > 35) {
          return str.substr(0, 20) + '...' + str.substr(str.length-10, str.length);
        }
        return str;
    },
    assetPathFormatting(path: string) {
        return path.replace(/\\/g, "/");
    },
    
    titleCase(str: string) {
       if(str) {
            const splitStr = str.toLowerCase().split(' ');
            for (let i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            return splitStr.join(' '); 
       } else {
           return str
       }
     },
     percentage(value: any) {
        return numeral(value).format('[0.0]');
      },
      htmlDecode(input: any){
        const e = document.createElement('div');
        e.innerHTML = input;
        // handle case of empty input .forEach(b=>b.removeAttribute('disabled'));
       let string= e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue as string;
        string=string.replace(/<(\w+)\s*(?:(?:(?:(?!class=|id=|name=)[^>]))*((?:class|id|name)=['"][^'"]*['"]\s*)?)(?:(?:(?:(?!class=|id=|name=)[^>]))*((?:class|id|name)=['"][^'"]*['"]\s*)?)(?:(?:(?:(?!class=|id=|name=)[^>]))*((?:class|id|name)=['"][^'"]*['"]\s*)?)[^>]*>/,'');
        return string;
    },
    htmlDecodeIput(input: any) {
        const doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
      }
}

