import { MutationTree } from "vuex";


import { CartState } from "./state";
import { CartMutationTypes } from "./mutation-types";

export type Mutations<S = CartState> = {
  [CartMutationTypes.PUSH_PRODUCT_CART](state: S, payload: any): void;
  [CartMutationTypes.SET_SELECT_SHIPPING_METHOD](state: S, payload: any): void;
  [CartMutationTypes.SET_ACTIVE_ORDER](state: S, payload: any): void;
  [CartMutationTypes.RESET_ACTIVE_ORDER](state: S, payload: any): void;
  [CartMutationTypes.SET_FETCHED_SHIPPING_METHODS](state: S, payload: any): void;
  [CartMutationTypes.SET_CHECKOUT_STATUS](state: S, payload: any): void;
  [CartMutationTypes.UNSET_CART_ITEMS](state: S, payload: any): void;
};

export const mutations: MutationTree<CartState> & Mutations = {
  [CartMutationTypes.PUSH_PRODUCT_CART](state: CartState, orderLines: any ) {
    state.cartItems=[...orderLines];
  },
  [CartMutationTypes.SET_ACTIVE_ORDER](state: CartState, activeOrder: any ) {
    state.activeOrder=activeOrder;
  },
  [CartMutationTypes.RESET_ACTIVE_ORDER](state: CartState ) {
    state.activeOrder=null;
  },
  [CartMutationTypes.SET_SELECT_SHIPPING_METHOD](state: CartState, selectedShippingMethod: any ) {
    state.selectedShippingMethod=selectedShippingMethod;
  },
  [CartMutationTypes.SET_FETCHED_SHIPPING_METHODS](state: CartState, shippingMethods: any ) {
    state.shippingMethods=shippingMethods;
  },
  [CartMutationTypes.SET_CART_ITEMS](state: CartState) {
    state.cartItems.push();
  },

  [CartMutationTypes.SET_CHECKOUT_STATUS](state: CartState) {
    state.checkoutStatus = null;
  },
  [CartMutationTypes.UNSET_CART_ITEMS](state: CartState, product: any) {
    const singleproduct = state.cartItems.find((p: any) => p.product.id === product.product.id);
    if (singleproduct) {
      state.cartItems = [...state.cartItems.filter((p: any) => p.product.id != product.product.id)];
    }
  },

};
