import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import moshaToast from 'mosha-vue-toastify'
import { DefaultApolloClient } from '@vue/apollo-composable'

import { ApolloClient } from 'apollo-client'

import { createHttpLink } from 'apollo-link-http'
import { onError } from "apollo-link-error"
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import 'mosha-vue-toastify/dist/style.css'

/* Theme variables */
import './theme/variables.css';

import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/owl.carousel.css';
import '@/assets/css/style.css';

import 'lightgallery/css/lightgallery.css'; 
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'intl-tel-input/build/css/intlTelInput.css';

import 'jquery';
import 'owl.carousel/dist/owl.carousel.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import '@/assets/js/jquery.firstVisitPopup.js';

import '@/assets/js/custom.js';

import { ToastNotify, _toastNotifyTitles } from '@/lib/toast-notify';
import {store} from '@/store'
import { GlobalFilters } from '@/lib/filters';
import Pagination from '@/components/Pagination.vue';
import VueEasyLightbox from 'vue-easy-lightbox'

const httpLink = createHttpLink({
    uri: process.env.NODE_ENV === 'development' ? 'https://ava-pharmacy-backend.onrender.com/shop-api' : process.env.NODE_ENV === 'production' ? 'https://ava-pharmacy-backend.onrender.com/shop-api' : `https://ava-pharmacy-backend.onrender.com/shop-api`,
    credentials: 'include', 

});

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>{
            const title=path?path[0]:'Error'
            console.log(`${message}`)
            return message
        })
    if (networkError) new ToastNotify({ title: _toastNotifyTitles['networkError'], description: networkError},'danger');//console.dir(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers, ...context }) => {
    const token = localStorage.getItem('user-token');
    return {
        headers: {
            ...headers,
            ...(token ? { authorization: `Bearer ${token}` } : {}),
        },
        ...context,
    };
});

// our custom "afterware" that checks each response and saves the sessionID
// if it contains an 'Authorization' header
const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const context = operation.getContext();
        const authHeader = context.response.headers.get('vendure-auth-token');
        if (authHeader) {
            localStorage.setItem("user-token", authHeader);
        }
        return response;
    });
});
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: []
      }
    }
  });

// Create the apollo client
export const apolloClient = new ApolloClient({
    link: afterwareLink.concat(authLink).concat(errorLink).concat(httpLink),
    cache: new InMemoryCache({
        addTypename: true,
        fragmentMatcher,
      }),
    connectToDevTools: true,
});

const app = createApp(App)
   .use(store)
  .use(IonicVue)
  .use(router);

app.use(moshaToast);
app.use(VueEasyLightbox);
app.component('ava-pagination', Pagination);

app.provide(DefaultApolloClient, apolloClient)
app.config.globalProperties.$filters={...GlobalFilters}
router.isReady().then(() => {
  app.mount('#app');
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);