import {
    Store as VuexStore,
    CommitOptions,
    DispatchOptions,
    Module,
  } from 'vuex';

  import { RootState } from '@/store';

  import { state } from './state';
  import { getters, Getters } from './getters';
  import { mutations, Mutations } from './mutations';
  import { actions, Actions } from './actions';

  import type { WishState } from './state';

  export { WishState as WishState };

export type WishListStore<S = WishState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'>
& {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
};

  export const store: Module<WishState, RootState> = {
    state,
    mutations,
    getters,
    actions,
  };