import { MutationTree } from 'vuex';


import {WishState} from './state';
import {  WishListMutationTypes } from './mutation-types';

export type Mutations<S = WishState> = {
  [WishListMutationTypes.SET_ITEM_TO_WISHLIST](state: S, payload: any): void;
  [WishListMutationTypes.UNSET_ITEM_FROM_WISHLIST](state: S, payload: any): void;


}

export const mutations: MutationTree<WishState> & Mutations = {
  [WishListMutationTypes.SET_ITEM_TO_WISHLIST](state: WishState, item: any ) {

    const wishBucket= state.items.find((i: any) => i.product.id === item.product.id);
    if (!wishBucket) {
      state.items.push(item);
    } else {
      wishBucket.quantity++;
    }
  },
  [WishListMutationTypes.UNSET_ITEM_FROM_WISHLIST](state: WishState, item: any) {
    const singleproduct = state.items.find((i: any) => i.product.id === item.product.id);
    if (singleproduct) {
      state.items = [...state.items.filter((i: any) => i.product.id !== item.product.id)];
    }
  },


};