
/*
background: #DF1F52 none repeat scroll 0 0;
color: #fff;
*/
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'ava-vpage',
  components: {  },
  props: {
    page: {
      type: Number,
      default: null,
    },
    current: {
      type: Number,
      default: 0,
    },
    activeColor: {
      type: String,
      default: '#DCEDFF',
    },
  },
  emits: ['update'],

  setup(props, { emit }) {
    const isActive = computed(() => {
      return props.page === props.current;
    });

    function clickHandler() {
      emit('update', props.page);
    }

    return { isActive, clickHandler };
  },
});
