import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { CheckoutState } from './state';

export type Getters = {
  getPaymentMethods(state: CheckoutState,rootState: RootState): any[];
  getSelectedpaymentMethod(state: CheckoutState,rootState: RootState): any;
}

export const getters: GetterTree<CheckoutState, RootState> & Getters = {
  getPaymentMethods: (state) => state.paymentMethods,
  getSelectedpaymentMethod: (state) => state.selectedpaymentMethod,
};
