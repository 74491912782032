import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { CoreState } from "./state";

export type Getters = {
  getNotification(state: CoreState, rootState: RootState): boolean;
  getFetchingActiveCustomerStatus(state: CoreState, rootState: RootState): boolean;
  getFetchingProductsStatus(state: CoreState, rootState: RootState): boolean;
  getFetchingCollectionsStatus(state:  CoreState, rootState: RootState): boolean;
  getPriceWithTaxMax(state:  CoreState, rootState: RootState): number;
  getPriceWithTaxMin(state:  CoreState, rootState: RootState): number;
};
export const getters: GetterTree<CoreState, RootState> & Getters = {
  getNotification: (state) => {
    return state.showNotification;
  },
  getFetchingActiveCustomerStatus: (state) => {
    return state.fetchingActiveCutomer;
  },
  getFetchingProductsStatus: (state) => {
    return state.fetchingProducts;
  },
  getFetchingCollectionsStatus: (state) => {
    return state.fetchingCollections;
  },
  getUserAUthenticating: (state) => {
    return state.userAUthenticating;
  },
  getUserCreatingPrescription: (state) => {
    return state.userCreatingPrescription;
  },
  getPriceWithTaxMax: (state) => {
    return state.priceWithTaxMax;
  },
  getPriceWithTaxMin: (state) => {
    return state.priceWithTaxMin;
  },
};