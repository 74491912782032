import { MutationTree } from "vuex";

import { State } from "./state";
import { ProductsMutationTypes } from "./mutation-types";
//Interface
export type Mutations<S = State> = {
  [ProductsMutationTypes.SET_PRODUCTS](state: S, payload: any): void;
  [ProductsMutationTypes.SET_TOPRATED_PRODUCTS](state: S, payload: any): void;
  [ProductsMutationTypes.SET_FEATURED_PRODUCTS](state: S, payload: any): void;
  [ProductsMutationTypes.SET_BEST_SELLERS](state: S, payload: any): void;
  [ProductsMutationTypes.SET_NEW_ARRIVALS](state: S, payload: any): void;
  [ProductsMutationTypes.SET_CHECK_AVAILABILITY](state: S, payload: any): void;
  [ProductsMutationTypes.SET_PRODUCT_BY_ID](state: S, payload: any): void;
  [ProductsMutationTypes.SET_PRODUCT_BY_SLUG](state: S, payload: any): void;
  [ProductsMutationTypes.SET_PRODUCT_TO_VIEWED](state: S, payload: any): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProductsMutationTypes.SET_PRODUCTS](state: State, products: any) {
    products.forEach((product: any) => {
      if (product) {
        product.assets.forEach((asset: any) => {
          const replaced = asset.source.replace(/\\/g, "/");
          asset.source = replaced;
        });
        product.variants.forEach((variant: any) => {
          variant.assets.forEach((asset: any) => {
            const replaced = asset.source.replace(/\\/g, "/");
            asset.source = replaced;
          });
        });
      }
    });
    state.products = products;
  },
  [ProductsMutationTypes.SET_TOPRATED_PRODUCTS](state: State, topRated: any) {
    state.topRated = topRated;
  },
  [ProductsMutationTypes.SET_FEATURED_PRODUCTS](state: State, featuredProducts: []) {
    state.featuredProducts = [];
    state.featuredProducts = [...featuredProducts];
  },
  [ProductsMutationTypes.SET_BEST_SELLERS](state: State, bestSellers: []) {
    state.bestSellers = [];
    state.bestSellers = [...bestSellers];
  },
  [ProductsMutationTypes.SET_NEW_ARRIVALS](state: State,newArrivals: []) {
    state.newArrivals = [];
    state.newArrivals = [...newArrivals];
  },
  [ProductsMutationTypes.SET_CHECK_AVAILABILITY](state: State, product: any) {
    state.selectedProduct = product;
  },
  [ProductsMutationTypes.SET_PRODUCT_BY_ID](state: State, product: any) {
    state.activeProduct = product;
  },
  [ProductsMutationTypes.SET_PRODUCT_BY_SLUG](state: State, product: any) {
    state.activeProduct = product;
  },
  [ProductsMutationTypes.SET_PRODUCT_TO_VIEWED](state: State, product: any) {
    if(product) {
          const found = state.viewedProducts.find((x: any) => x.id == product.id);
          if (!found) {
            state.viewedProducts.push(product);
          }
    }
  }
};
