import gql from "graphql-tag";

export const ADD_PAYMENT_TO_ORDER = gql`
  mutation AddPaymentToOrderMutation($addPaymentToOrderInput: PaymentInput!) {
    addPaymentToOrder(input: $addPaymentToOrderInput) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on OrderPaymentStateError {
        errorCode
        message
      }
      ... on IneligiblePaymentMethodError {
        errorCode
        message
        eligibilityCheckerMessage
      }
      ... on PaymentFailedError {
        errorCode
        message
        paymentErrorMessage
      }
      ... on PaymentDeclinedError {
        errorCode
        paymentErrorMessage
      message
      }
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
      ... on NoActiveOrderError {
        errorCode
        message
      }
    }
  }
`;

export const ADD_ITEM_TO_ORDER = gql`
  mutation Mutation(
    $addItemToOrderProductVariantId: ID!
    $addItemToOrderQuantity: Int!
  ) {
    addItemToOrder(
      productVariantId: $addItemToOrderProductVariantId
      quantity: $addItemToOrderQuantity
    ) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on OrderModificationError {
        errorCode
        message
      }
      ... on OrderLimitError {
        errorCode
        message
        maxItems
      }
      ... on NegativeQuantityError {
        errorCode
        message
      }
      ... on InsufficientStockError {
        errorCode
        message
        quantityAvailable
        order {
          code
          orderPlacedAt
        }
      }
    }
  }
`;

export const ADJUST_ORDER_LINE = gql`
  mutation Mutation(
    $adjustOrderLineOrderLineId: ID!
    $adjustOrderLineQuantity: Int!
  ) {
    adjustOrderLine(
      orderLineId: $adjustOrderLineOrderLineId
      quantity: $adjustOrderLineQuantity
    ) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on OrderModificationError {
        errorCode
        message
      }
      ... on OrderLimitError {
        errorCode
        message
        maxItems
      }
      ... on NegativeQuantityError {
        errorCode
        message
      }
      ... on InsufficientStockError {
        errorCode
        message
        quantityAvailable
        order {
          code
          orderPlacedAt
        }
      }
    }
  }
`;

export const APPLY_COUPON_CODE = gql`
  mutation Mutation($applyCouponCodeCouponCode: String!) {
    applyCouponCode(couponCode: $applyCouponCodeCouponCode) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
        currencyCode
        couponCodes
        promotions {
          name
          perCustomerUsageLimit
          couponCode
        }
      }
      ... on CouponCodeExpiredError {
        errorCode
        message
        couponCode
      }
      ... on CouponCodeInvalidError {
        errorCode
        message
        couponCode
      }
      ... on CouponCodeLimitError {
        errorCode
        message
        couponCode
        limit
      }
    }
  }
`;

export const REMOVE_COUPON_CODE = gql`
  mutation RemoveCouponCodeMutation($removeCouponCodeCouponCode: String!) {
    removeCouponCode(couponCode: $removeCouponCodeCouponCode) {
      orderPlacedAt
      code
      state
    }
  }
`;

export const TRANSITION_ORDER_TO_STATE = gql`
  mutation Mutation($transitionOrderToStateState: String!) {
    transitionOrderToState(state: $transitionOrderToStateState) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
    }
  }
`;

export const SET_ORDER_SHIPPING_ADDRESS = gql`
  mutation SetOrderShippingAddressMutation(
    $setOrderShippingAddressInput: CreateAddressInput!
  ) {
    setOrderShippingAddress(input: $setOrderShippingAddressInput) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on NoActiveOrderError {
        errorCode
        message
      }
    }
  }
`;

export const SET_ORDER_BILLING_ADDRESS = gql`
  mutation SetOrderBillingAddressMutation(
    $setOrderBillingAddressInput: CreateAddressInput!
  ) {
    setOrderBillingAddress(input: $setOrderBillingAddressInput) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on NoActiveOrderError {
        errorCode
        message
      }
    }
  }
`;

export const SET_ORDER_SHIPPING_METHOD = gql`
  mutation SetOrderShippingMethodMutation(
    $setOrderShippingMethodShippingMethodId: ID!
  ) {
    setOrderShippingMethod(
      shippingMethodId: $setOrderShippingMethodShippingMethodId
    ) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on OrderModificationError {
        errorCode
        message
      }
      ... on IneligibleShippingMethodError {
        errorCode
        message
      }
      ... on NoActiveOrderError {
        errorCode
        message
      }
    }
  }
`;

export const SET_CUSTOMER_FOR_ORDER = gql`
  mutation SetCustomerForOrderMutation(
    $setCustomerForOrderInput: CreateCustomerInput!
  ) {
    setCustomerForOrder(input: $setCustomerForOrderInput) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on AlreadyLoggedInError {
        errorCode
        message
      }
      ... on EmailAddressConflictError {
        errorCode
        message
      }
      ... on NoActiveOrderError {
        message
        errorCode
      }
    }
  }
`;

export const REMOVE_ORDER_LINE = gql`
  mutation RemoveOrderLineMutation($removeOrderLineOrderLineId: ID!) {
    removeOrderLine(orderLineId: $removeOrderLineOrderLineId) {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on OrderModificationError {
        errorCode
        message
      }
    }
  }
`;

export const REMOVE_ALL_ORDER_LINES = gql`
  mutation RemoveAllOrderLinesMutation {
    removeAllOrderLines {
      ... on Order {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        customer {
          firstName
        }
          shippingAddress {          
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        billingAddress {
          city
          fullName
          company
          streetLine1
          streetLine2
          postalCode
          country
          countryCode
          phoneNumber
          province
        }
        payments {
          transactionId
        }
        history {
          items {
            type
          }
        }
        lines {
          id
          createdAt
          updatedAt
          quantity
          taxRate
          linePriceWithTax
          linePrice
          featuredAsset {
            source
            preview
          }
          productVariant {
            id
            name
            product{
              slug
            }
            featuredAsset {
              source
              preview
            }
          }
          unitPriceWithTax
          unitPrice
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
        }
        subTotal
        subTotalWithTax
        totalWithTax
        total
        customFields {
          createdFromPrescription
        }
        taxSummary {
          taxRate
          taxBase
          taxTotal
          description
        }
        totalQuantity
      }
      ... on OrderModificationError {
        errorCode
        message
      }
    }
  }
`;

export const LOGIN = gql`
  mutation LoginMutation(
    $loginUsername: String!
    $loginPassword: String!
    $loginRememberMe: Boolean
  ) {
    login(
      username: $loginUsername
      password: $loginPassword
      rememberMe: $loginRememberMe
    ) {
      ... on CurrentUser {
        id
        identifier
        channels {
          token
          code
          id
        }
      }
      ... on InvalidCredentialsError {
        errorCode
        message
        authenticationError
      }
      ... on NotVerifiedError {
        errorCode
        message
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
    }
  }
`;

export const AUTHENTICATE = gql`
  mutation AuthenticateMutation(
    $authenticateInput: AuthenticationInput!
    $authenticateRememberMe: Boolean
  ) {
    authenticate(
      input: $authenticateInput
      rememberMe: $authenticateRememberMe
    ) {
      ... on CurrentUser {
        id
        identifier
        channels {
          id
          token
          code
        }
      }
      ... on InvalidCredentialsError {
        errorCode
        message
        authenticationError
      }
      ... on NotVerifiedError {
        errorCode
        message
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation LogoutMutation {
    logout {
      success
    }
  }
`;

export const REGISTER_CUSTOMER_ACCOUNT = gql`
  mutation RegisterCustomerAccountMutation(
    $registerCustomerAccountInput: RegisterCustomerInput!
  ) {
    registerCustomerAccount(input: $registerCustomerAccountInput) {
      ... on Success {
        success
      }
      ... on MissingPasswordError {
        errorCode
        message
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
    }
  }
`;

export const REFRESH_CUSTOMER_VERIFICATION = gql`
  mutation RefreshCustomerVerificationMutation(
    $refreshCustomerVerificationEmailAddress: String!
  ) {
    refreshCustomerVerification(
      emailAddress: $refreshCustomerVerificationEmailAddress
    ) {
      ... on Success {
        success
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomerMutation($updateCustomerInput: UpdateCustomerInput!) {
    updateCustomer(input: $updateCustomerInput) {
      firstName
      lastName
      id
      phoneNumber
      title
      emailAddress
      addresses {
        defaultShippingAddress
        defaultBillingAddress
        phoneNumber
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
          code
          name
        }
        id
      }
    }
  }
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
 mutation CreateCustomerAddressMutation(
    $createCustomerAddressInput: CreateAddressInput!
  ) {
    createCustomerAddress(input: $createCustomerAddressInput) {
      id
      fullName
      company
      streetLine1
      city
      phoneNumber
      postalCode
      province
      streetLine2
      country {
        code
        name
      }
      defaultShippingAddress
      defaultBillingAddress
    }
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddressMutation(
    $updateCustomerAddressInput: UpdateAddressInput!
  ) {
    updateCustomerAddress(input: $updateCustomerAddressInput) {
      id
      fullName
      company
      streetLine1
      city
      phoneNumber
      postalCode
      province
      streetLine2
      country {
        code
        name
      }
      defaultShippingAddress
      defaultBillingAddress
    }
  }
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation DeleteCustomerAddressMutation($deleteCustomerAddressId: ID!) {
    deleteCustomerAddress(id: $deleteCustomerAddressId) {
      success
    }
  }
`;

export const LOG_OUT = gql`
  mutation LogoutMutation {
    logout {
      success
    }
  }
`;

export const VERIFY_CUSTOMER_ACCOUNT = gql`
  mutation VerifyCustomerAccountMutation(
    $verifyCustomerAccountToken: String!
    $verifyCustomerAccountPassword: String
  ) {
    verifyCustomerAccount(
      token: $verifyCustomerAccountToken
      password: $verifyCustomerAccountPassword
    ) {
      ... on CurrentUser {
        id
        channels {
          id
          token
          code
        }
        identifier
      }
      ... on VerificationTokenExpiredError {
        errorCode
        message
      }
      ... on MissingPasswordError {
        errorCode
        message
      }
      ... on PasswordAlreadySetError {
        errorCode
        message
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
      ... on VerificationTokenInvalidError {
        errorCode
        message
      }
    }
  }
`;

export const UPDATE_CUSTOMER_PASSWORD = gql`
  mutation UpdateCustomerPasswordMutation(
    $updateCustomerPasswordCurrentPassword: String!
    $updateCustomerPasswordNewPassword: String!
  ) {
    updateCustomerPassword(
      currentPassword: $updateCustomerPasswordCurrentPassword
      newPassword: $updateCustomerPasswordNewPassword
    ) {
      ... on Success {
        success
      }
      ... on InvalidCredentialsError {
        errorCode
        message
        authenticationError
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
    }
  }
`;

export const REQUEST_UPDATE_CUSTOMER_EMAIL_ADDRESS = gql`
  mutation RequestUpdateCustomerEmailAddressMutation(
    $requestUpdateCustomerEmailAddressPassword: String!
    $requestUpdateCustomerEmailAddressNewEmailAddress: String!
  ) {
    requestUpdateCustomerEmailAddress(
      password: $requestUpdateCustomerEmailAddressPassword
      newEmailAddress: $requestUpdateCustomerEmailAddressNewEmailAddress
    ) {
      ... on Success {
        success
      }
      ... on InvalidCredentialsError {
        errorCode
        message
        authenticationError
      }
      ... on EmailAddressConflictError {
        errorCode
        message
      }
      ... on NativeAuthStrategyError {
        message
      }
      ... on NativeAuthStrategyError {
        errorCode
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordResetMutation(
    $requestPasswordResetEmailAddress: String!
  ) {
    requestPasswordReset(emailAddress: $requestPasswordResetEmailAddress) {
      ... on Success {
        success
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation(
    $resetPasswordToken: String!
    $resetPasswordPassword: String!
  ) {
    resetPassword(
      token: $resetPasswordToken
      password: $resetPasswordPassword
    ) {
      ... on CurrentUser {
        id
        identifier
        channels {
          id
          token
          code
        }
      }
      ... on PasswordResetTokenInvalidError {
        errorCode
        message
      }
      ... on PasswordResetTokenExpiredError {
        errorCode
        message
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
    }
  }
`;

export const TOGGLE_FAVORITE = gql`
  mutation ToggleFavoriteMutation($toggleFavoriteProductId: ID!) {
    toggleFavorite(productId: $toggleFavoriteProductId) {
      totalItems
      items {
        id
        updatedAt
        product {
          name
          slug
        }
      }
    }
  }
`;

export const SUBMIT_PRESCRIPTION = gql`
  mutation SubmitPrescriptionMutation(
    $submitPrescriptionInput: SubmitPrescriptionInput!
  ) {
    submitPrescription(input: $submitPrescriptionInput) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const SUBMIT_PRODUCT_REVIEW = gql`
  mutation SubmitPrescriptionMutation(
    $submitProductReviewInput: SubmitProductReviewInput!
  ) {
    submitProductReview(input: $submitProductReviewInput) {
      id
      createdAt
    }
  }
`;

export const VOTE_ON_REVIEW = gql`
  mutation VoteOnReviewMutation(
    $voteOnReviewId: ID!
    $voteOnReviewVote: Boolean!
  ) {
    voteOnReview(id: $voteOnReviewId, vote: $voteOnReviewVote) {
      upvotes
      downvotes
      id
    }
  }
`;

export const ADD_SUBSCRIPTION_PHONE = gql`
  mutation AddSubscriptionPhoneMutation(
    $addSubscriptionPhoneInput: [PhoneAddInput!]!
  ) {
    addSubscriptionPhone(input: $addSubscriptionPhoneInput) {
      phone
      createdAt
      id
    }
  }
`;

export const ADD_SUBSCRIPTION_EMAIL = gql`
  mutation AddSubscriptionEmailMutation(
    $addSubscriptionEmailInput: [EmailAddInput!]!
  ) {
    addSubscriptionEmail(input: $addSubscriptionEmailInput) {
      id
      email
      createdAt
    }
  }
`;

export const CUSTOMER_SUBMIT_PRESCRIPTION = gql`
mutation Mutation($customerSubmitPrescriptionInput: CustomerSubmitPrescriptionInput!) {
  customerSubmitPrescription(input: $customerSubmitPrescriptionInput) {
   id
    updatedAt
    createdAt
  }
}`;
