import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";

import { WishState } from "./state";
import { Mutations } from "./mutations";
import { WishListMutationTypes } from "./mutation-types";

import { WishListActionTypes } from "./action-types";
import { apolloClient } from '@/main'
import { TOGGLE_FAVORITE } from "@/grapql/mutations";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<WishState, RootState>, "commit">;

export interface Actions {
  [WishListActionTypes.ADD_PRODUCT_TO_WISHLIST]( { commit }: AugmentedActionContext,  { payload: data }: any  ): Promise<boolean>;
  [WishListActionTypes.DELETE_ITEM_FROM_WISHLIST](   { commit }: AugmentedActionContext,{ payload: data }: any  ): Promise<boolean>;
}

export const actions: ActionTree<WishState, RootState> & Actions = {
  async [WishListActionTypes.ADD_PRODUCT_TO_WISHLIST](
    { commit },
    payload: any
  ) {
    
    await apolloClient.mutate({mutation: TOGGLE_FAVORITE,variables:{toggleFavoriteProductId:payload.product.id}});
    return new Promise(() => {
      setTimeout(() => {
        commit(WishListMutationTypes.SET_ITEM_TO_WISHLIST, payload);
        return true;
      }, 500);
    });
  },
  async [WishListActionTypes.DELETE_ITEM_FROM_WISHLIST]({ commit }, payload: any) {
    return new Promise(() => {
      setTimeout(() => {
        commit(WishListMutationTypes.UNSET_ITEM_FROM_WISHLIST, payload);
        return true;
      }, 500);
    });
  },
};
