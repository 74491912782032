import gql from "graphql-tag";
export const CONFIGURABLE_OPERATION_DEF_FRAGMENT = gql`
    fragment ConfigurableOperationDef on ConfigurableOperationDefinition {
        args {
            name
            type
            list
            ui
            label
        }
        code
        description
    }
`;
export const GET_ADJUSTMENT_OPERATIONS = gql`
    query GetAdjustmentOperations {
        promotionConditionsFr {
            ...ConfigurableOperationDef
        }
        promotionActionsFr {
            ...ConfigurableOperationDef
        }
    }
    ${CONFIGURABLE_OPERATION_DEF_FRAGMENT}
`;
export const GET_ACTIVE_CUSTOMER = gql`
  query Query {
    activeCustomer {
      id
      firstName
      lastName
      phoneNumber
      emailAddress
      addresses {
        id
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
          id
          code
          name
        }
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
      }
      orders(options: { sort: { updatedAt: DESC } }) {
        items {
          id
          createdAt
          updatedAt
          orderPlacedAt
          code
          state
          active
          customer {
            firstName
          }
          shippingAddress {
            city
          }
          billingAddress {
            city
          }
          payments {
            transactionId
          }
          history {
            items {
              type
              updatedAt
              data
            }
          }
          lines {
            id
            createdAt
            updatedAt
            quantity
            taxRate
            linePriceWithTax
            linePrice
            featuredAsset {
              source
              preview
            }
            productVariant {
              id
              name
              featuredAsset {
                source
                preview
              }
              sku
              product {
                slug
                name
              }
            }
            unitPriceWithTax
            unitPrice
            unitPriceChangeSinceAdded
            unitPriceWithTaxChangeSinceAdded
            discountedUnitPrice
            discountedUnitPriceWithTax
            proratedUnitPrice
            proratedUnitPriceWithTax
          }
          subTotal
          subTotalWithTax
          totalWithTax
          total
          customFields {
            createdFromPrescription
          }
          taxSummary {
            taxRate
            taxBase
            taxTotal
            description
          }
          totalQuantity
          shipping
          shippingWithTax
          currencyCode
          fulfillments {
            state
            method
            trackingCode
          }
          discounts {
            amount
            amountWithTax
            description
          }
          surcharges {
            taxRate
            priceWithTax
            price
            sku
            description
          }
        }
      }
      user {
        lastLogin
      }
      favorites {
        items {
          id
          product {
            id
            name
            variants {
              id
              productId
              currencyCode
              price
            }
          }
        }
      }
      title
      prescriptions {
        items {
          prescriptionUploads {
            id
            upload
          }
          state
          response
          responseCreatedAt
          order {
            state
            code
            id
          }
          customer {
            firstName
            id
          }
          note
        }
        totalItems
      }
    }
  }
`;

export const GET_BANNERS = gql`
query Query($productPromotionsOptions: ProductPromotionListOptions) {
  productPromotions(options: $productPromotionsOptions) {
    items {
      name
      bannerSection
      description
      assets {
        source
        preview
      }
      featuredAsset {
        source
        preview
      }
      promotion {
        couponCode
        name
        conditions {
          code
          args {
            value
            name
          }
        }
        actions {
          args {
            value
            name
          }
          code
        }
        updatedAt
        createdAt
      }
    }
  }
}
`;

export const GET_ACTIVE_PROMOTIONS = gql`
query Query($activePromotionsOptions: PromotionListOptions) {
  activePromotions(options: $activePromotionsOptions) {
    items {
      endsAt
      name
      actions {
        args {
          name
          value
        }
        code
      }
      conditions {
        args {
          value
          name
        }
        code
      }
    }
    totalItems
  }
}`;
export const GET_ACTIVE_ORDER = gql`
  query Query {
    activeOrder {
      id
      createdAt
      updatedAt
      orderPlacedAt
      code
      state
      active
      subTotal
      subTotalWithTax
      totalWithTax
      total
      totalQuantity
      shipping
      shippingWithTax
      currencyCode
      customer {
        firstName
      }
      shippingAddress {
        city
      }
      billingAddress {
        city
      }
      payments {
        transactionId
      }
      history {
        items {
          type
        }
      }
      lines {
        id
        createdAt
        updatedAt
        quantity
        taxRate
        linePriceWithTax
        linePrice
        featuredAsset {
          source
          preview
        }
        productVariant {
          id
          name
          featuredAsset {
            source
            preview
          }
          sku
          product {
            slug
            name
          }
        }
        unitPriceWithTax
        unitPrice
        unitPriceChangeSinceAdded
        unitPriceWithTaxChangeSinceAdded
        discountedUnitPrice
        discountedUnitPriceWithTax
        proratedUnitPrice
        proratedUnitPriceWithTax
      }
      customFields {
        createdFromPrescription
      }
      taxSummary {
        taxRate
        taxBase
        taxTotal
        description
      }
      fulfillments {
        state
        method
        trackingCode
      }
      discounts {
        amount
        amountWithTax
        description
      }
      surcharges {
        taxRate
        priceWithTax
        price
        sku
        description
      }
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query Query($collectionsOptions: CollectionListOptions) {
    collections(options: $collectionsOptions) {
      items {
        id
        name
        slug
        featuredAsset {
          id
          preview
          source
        }
        parent {
          id
          name
        }
        children {
          name
          slug
          featuredAsset {
            source
            preview
            id
          }
        }
        assets {
          id
          source
        }
      productVariants(options: { take: 5 }) {
        totalItems
        items {
          name
          product {
            slug
            name
          }
        }
      }
      }
      totalItems
    }
  }
`;

export const GET_COLLECTION = gql`
  query Query($collectionId: ID, $collectionSlug: String) {
    collection(id: $collectionId, slug: $collectionSlug) {
      id
      name
      slug
      featuredAsset {
        id
        preview
        source
      }
      parent {
        id
        name
      }
      children {
        name
        slug
        featuredAsset {
          source
          preview
          id
        }
      }
      assets {
        id
        source
      }
      productVariants {
        totalItems
      }
    }
  }
`;
export const GET_PRODUCTS_BY_COLLECTION = gql`
query Query($collectionId: ID, $collectionSlug: String, $productVariantsOptions: ProductVariantListOptions) {
  collection(id: $collectionId, slug: $collectionSlug) {
    id
    name
    productVariants(options: $productVariantsOptions) {
      totalItems
      items {
        id
        priceWithTax
        stockLevel
        currencyCode
        product {
          id
          updatedAt
          name
          slug
          description
          featuredAsset {
            source
            preview
          }
          assets {
            source
            preview
          }
          variants {
            id
            priceWithTax
            stockLevel
            sku
            name
            featuredAsset {
              source
              preview
            }
            assets {
              source
              preview
            }
          }
          optionGroups {
            name
            code
          }
          facetValues {
            facet {
              name
              code
            }
            code
            name
          }
          reviews {
            items {
              responseCreatedAt
              response
              state
              downvotes
              upvotes
              authorName
              rating
              body
              summary
            }
            totalItems
          }
          recommendations {
            type
            recommendation {
              name
              slug
              id
              description
            }
          }
          reviewsHistogram {
            bin
            frequency
          }
          customFields {
            reviewRating
            reviewCount
          }
        }
      }
    }
    breadcrumbs {
      slug
      name
    }
    slug
  }
}`;
export const GET_PRODUCTS_BY_BRAND = gql`
query Query($productsByBrandOptions: ProductListOptions, $productsByBrandFacetCode: String) {
  productsByBrand(options: $productsByBrandOptions, facetCode: $productsByBrandFacetCode) {
    totalItems
    items {      
        id
        name
        slug
        featuredAsset {
          source
          preview
        }
        assets {
          source
          preview
        }        
        facetValues {
          code
          code
        }
        variants {
            id
            priceWithTax
            stockLevel
            sku
            currencyCode
          }
          optionGroups {
            name
            code
          }
          reviews {
            items {
              responseCreatedAt
              response
              state
              downvotes
              upvotes
              authorName
              rating
              body
              summary
            }
            totalItems
          }
          reviewsHistogram {
            bin
            frequency
          }
          customFields {
            reviewRating
            reviewCount
          }
      slug
    }      
    }
  }`;
export const GET_PRODUCTS = gql`
  query Query($productsOptions: ProductListOptions) {
    products(options: $productsOptions) {
      items {
        id
        name
        slug
        description
        facetValues {
          facet {
            name
            code
          }
          name
          code
        }
        assets {
          source
        }
        optionGroups {
          id
          name
          options {
            id
            name
            code
            groupId
          }
        }
        featuredAsset {
          id
          source
        }
        variants {
          currencyCode
          id
          sku
          name
          price
          assets {
            source
          }
          facetValues {
            id
            name
            facet {
              id
              name
            }
          }
          options {
            id
            name
            code
            groupId
          }
        }
        collections {
          id
          name
          slug
        }
      }
      totalItems
    }
  }
`;
export const GET_PRODUCT = gql`
  query Query($productId: ID) {
    product(id: $productId) {
      id
      updatedAt
      name
      slug
      description
      featuredAsset {
        source
        preview
      }
      assets {
        source
        preview
      }
      variants {
        id
        priceWithTax
        stockLevel
        sku
        name
        featuredAsset {
          source
          preview
        }
        assets {
          source
          preview
        }
      }
      optionGroups {
        name
        code
      }
      facetValues {
        facet {
          name
          code
        }
        code
        name
      }
      reviews {
        items {
          createdAt
          responseCreatedAt
          response
          state
          downvotes
          upvotes
          authorName
          rating
          body
          summary
        }
        totalItems
      }
      recommendations {
        type
        recommendation {
          name
          slug
          id
          description
        }
      }
      reviewsHistogram {
        bin
        frequency
      }
      customFields {
        reviewRating
        reviewCount
      }
    }
  }
`;
export const GET_PRODUCT_SLUG = gql`
  query Query($productSlug: String) {
    product(slug: $productSlug) {
      id
      updatedAt
      name
      slug
      description
      featuredAsset {
        source
        preview
      }
      assets {
        source
        preview
      }
      variants {
        id
        priceWithTax
        stockLevel
        sku
        name
        featuredAsset {
          source
          preview
        }
        assets {
          source
          preview
        }
      }
      optionGroups {
        name
        code
      }
      facetValues {
        facet {
          name
          code
        }
        code
        name
      }
      reviews {
        items {
          createdAt
          responseCreatedAt
          response
          state
          downvotes
          upvotes
          authorName
          rating
          body
          summary
        }
        totalItems
      }
      recommendations {
        type
        recommendation {
          name
          slug
          id
          description
        }
      }
      reviewsHistogram {
        bin
        frequency
      }
      customFields {
        reviewRating
        reviewCount
      }
    }
  }
`;
export const SEARCH_DOCUMENT = gql`
  query Query($searchInput: SearchInput!) {
    search(input: $searchInput) {
      items {
        productName
        sku
        slug
        productId
        productVariantName
        productAsset {
          preview
          id
          focalPoint {
            x
            y
          }
        }
        currencyCode

        price {
          __typename
        }
        priceWithTax {
          ... on SinglePrice {
            value
          }
        }
        score
      }
      totalItems
    }
  }
`;

export const GET_LATEST_PRODUCTS = gql`
  query Query {
    latestProducts {
      id
      name
      slug
      assets {
        source
        preview
      }
      description
      reviews {
        items {
          createdAt
          summary
          body
          rating
          createdAt
          upvotes
          downvotes
          authorLocation
          authorName
        }
      }
      reviewsHistogram {
        bin
        frequency
      }
      recommendations {
        recommendation {
          id
          name
          slug
        }
      }
      variants {
        sku
        name
        id
        featuredAsset {
          source
          preview
        }
        priceWithTax
      }
      optionGroups {
        name
        options {
          name
        }
      }
      facetValues {
        name
        facet {
          name
        }
      }
    }
  }
`;

export const GET_TOP_SELLER_PRODUCTS = gql`
  query Query {
    topSellerProducts {
      id
      name
      slug
      assets {
        source
        preview
      }
      description
      reviews {
        items {
          createdAt
          summary
          body
          rating
          createdAt
          upvotes
          downvotes
          authorLocation
          authorName
        }
      }
      reviewsHistogram {
        bin
        frequency
      }
      recommendations {
        recommendation {
          id
          name
          slug
        }
      }
      variants {
        sku
        name
        id
        featuredAsset {
          source
          preview
        }
        priceWithTax
      }
      optionGroups {
        name
        options {
          name
        }
      }
      facetValues {
        name
        facet {
          name
        }
      }
    }
  }
`;

export const GET_TOP_RATED_PRODUCTS = gql`
  query Query {
    topRatedProducts {
      id
      name
      slug
      assets {
        source
        preview
      }
      description
      reviews {
        items {
          createdAt
          summary
          body
          rating
          createdAt
          upvotes
          downvotes
          authorLocation
          authorName
        }
      }
      reviewsHistogram {
        bin
        frequency
      }
      recommendations {
        recommendation {
          id
          name
          slug
        }
      }
      variants {
        sku
        name
        id
        featuredAsset {
          source
          preview
        }
        priceWithTax
      }
      optionGroups {
        name
        options {
          name
        }
      }
      facetValues {
        name
        facet {
          name
        }
      }
    }
  }
`;

export const GET_PRESCRIPTIONS = gql`
  query Query($prescriptionsOptions: PrescriptionListOptions) {
    prescriptions(options: $prescriptionsOptions) {
      totalItems
      items {
        createdAt
        id
        state
        response
        responseCreatedAt
        order {
          code
          state
          orderPlacedAt
        }
      }
    }
  }
`;
export const GET_PRESCRIPTION = gql`
  query Query($prescriptionId: ID!) {
    prescription(id: $prescriptionId) {
      order {
        orderPlacedAt
        code
        state
      }
      id
      createdAt
      state
      response
      responseCreatedAt
    }
  }
`;
export const GET_ORDER_BY_CODE = gql`
  query Query($orderByCodeCode: String!) {
    orderByCode(code: $orderByCodeCode) {
      id
      createdAt
      updatedAt
      orderPlacedAt
      code
      state
      active
      subTotal
      subTotalWithTax
      totalWithTax
      total
      totalQuantity
      shipping
      shippingWithTax
      currencyCode
      customer {
        firstName
      }
      shippingAddress {
        city
      }
      billingAddress {
        city
      }
      payments {
        transactionId
      }
      history {
        items {
          type
        }
      }
      lines {
        id
        createdAt
        updatedAt
        quantity
        taxRate
        linePriceWithTax
        linePrice
        featuredAsset {
          source
          preview
        }
        productVariant {
          id
          name
          featuredAsset {
            source
            preview
          }
          sku
          product {
            slug
            name
          }
        }
        unitPriceWithTax
        unitPrice
        unitPriceChangeSinceAdded
        unitPriceWithTaxChangeSinceAdded
        discountedUnitPrice
        discountedUnitPriceWithTax
        proratedUnitPrice
        proratedUnitPriceWithTax
      }
      customFields {
        createdFromPrescription
      }
      taxSummary {
        taxRate
        taxBase
        taxTotal
        description
      }
      fulfillments {
        state
        method
        trackingCode
      }
      discounts {
        amount
        amountWithTax
        description
      }
      surcharges {
        taxRate
        priceWithTax
        price
        sku
        description
      }
    }
  }
`;
export const GET_ORDER_BY_ID = gql`
  query Query($orderId: ID!) {
    order(id: $orderId) {
      id
      createdAt
      updatedAt
      orderPlacedAt
      code
      state
      active
      subTotal
      subTotalWithTax
      totalWithTax
      total
      totalQuantity
      shipping
      shippingWithTax
      currencyCode
      customer {
        firstName
      }
      shippingAddress {
        city
      }
      billingAddress {
        city
      }
      payments {
        transactionId
      }
      history {
        items {
          type
        }
      }
      lines {
        id
        createdAt
        updatedAt
        quantity
        taxRate
        linePriceWithTax
        linePrice
        featuredAsset {
          source
          preview
        }
        productVariant {
          id
          name
          featuredAsset {
            source
            preview
          }
          sku
          product {
            slug
            name
          }
        }
        unitPriceWithTax
        unitPrice
        unitPriceChangeSinceAdded
        unitPriceWithTaxChangeSinceAdded
        discountedUnitPrice
        discountedUnitPriceWithTax
        proratedUnitPrice
        proratedUnitPriceWithTax
      }
      customFields {
        createdFromPrescription
      }
      taxSummary {
        taxRate
        taxBase
        taxTotal
        description
      }
      fulfillments {
        state
        method
        trackingCode
      }
      discounts {
        amount
        amountWithTax
        description
      }
      surcharges {
        taxRate
        priceWithTax
        price
        sku
        description
      }
    }
  }
`;
export const GET_NEXT_ORDER_STATES = gql`
  query Query {
    nextOrderStates
  }
`;

export const GET_ELIGIBLE_SHIPPING_METHODS = gql`
  query Query {
    eligibleShippingMethods {
      id
      price
      priceWithTax
      code
      name
      description
      metadata
    }
  }
`;

export const GET_ELIGIBLE_PAYMENT_METHODS = gql`
  query Query {
    eligiblePaymentMethods {
      id
      code
      name
      description
      isEligible
      eligibilityMessage
    }
  }
`;

export const GET_AVAILABILITY_STATUS = gql`
  query Query($productId: ID) {
    product(id: $productId) {
      variants {
        stockLevel
      }
    }
  }
`;
export const GET_IFRAME = gql`
query Query($getIframeIntput: PesapalDirectOrderInfoInput!) {
  getIframe(intput: $getIframeIntput) {
    base_string
  }
}
`;
