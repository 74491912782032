import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';

export type Getters = {
  getActiveCustomer(state: State): any;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getActiveCustomer: (state) => state.activeCustomer
};